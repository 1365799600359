import RenderTeacherModules from '../Renders/RenderTeacherModules';
import './teachermodules.css';
import { useModulesContext } from '../../../context/ModulesContext';
import Spinner from '../../../misc/Spinner';
import React, { useEffect } from 'react';
import Navbar from '../../Navbar/Navbar';
import Account from '../../Account/Account';
import AuthCheck from '../../../services/auth/AuthCheck';
import { useYearsContext } from '../../../context/YearsContext';
import API from '../../../API';
import { nanoid } from 'nanoid';
const TeacherModules = () => {
  AuthCheck();
  const { teacherModuleSubscriptions, handleAllTeacherModules } = useModulesContext();
  const { years, chosenYear, currentYear } = useYearsContext();
  const chosenYearId = years.find(o => o.study_year === currentYear.studyYear);
  useEffect(() => {
    const result = years.find(o => o.study_year === chosenYear);
    API.get('/user/module-subscriptions', {
      params: {
        study_year: result.study_year,
        per_page: 30
      }
    })
    .then(response => {
      handleAllTeacherModules(response.data);
    });
  }, [chosenYear, chosenYearId]);
  return (
    <>
      <Navbar />
      <Account />
      <div className="container-fluid bg-gray-50 ml-80 w-3/4 min-h-screen">
        <h3 className="text-4xl font-bold mt-40">Mokytojo moduliai</h3>
        <div className='mt-5 overflow-x-hidden shadow-md sm:rounded-lg'>
          <table className='w-full text-sm text-left text-gray-500 mr-7'>
            <thead className='text-xs uppercase bg-blue-500 text-white'>
              {teacherModuleSubscriptions?.data ?
              <tr>
                <td className='px-3 py-3'>Grupė</td>
                <td className='px-3 py-3'>Modulis</td>
              </tr> : null }
            </thead>
            <tbody>
            {(teacherModuleSubscriptions?.data)
              ? teacherModuleSubscriptions.data.map(d =>
                <tr key={nanoid()} id={d.id} className="bg-white border-b">
                  <td className='px-3 py-4 font-medium text-gray-900 flex flex-wrap w-72'>{d.department}</td>
                  <td>{d.module}</td>
                </tr>
              )
              :
              <div className='m-1 p-1'>
                <img className='mx-auto' src="https://i.gifer.com/XOsX.gif" alt="Krovimo nuotrauka" />
                <p className='text-3xl'>Duomenys kraunami...</p>
                <p>Tai gali užtrukti iki 2 minučių!</p>
                <br />
                <p>Tuo tarpu galite pasivaikščioti arba pasidaryti kavos ☕</p>
              </div>
              }
            </tbody>
          </table>
        </div>
      </div>

    </>
  );
};
export default TeacherModules;
