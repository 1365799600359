import './SchoolYear.css';
import { useYearsContext } from '../../context/YearsContext';
import React from 'react';
import PostAddYear from '../../services/post/PostAddYear';
import RenderYears from './RenderYears';
import Navbar from '../Navbar/Navbar';
import Account from '../Account/Account';
import AuthCheck from '../../services/auth/AuthCheck';
const SchoolYear = () => {
  AuthCheck();
  const { years } = useYearsContext();
  const addYear = () => {
    PostAddYear();
  };
  return (
    <>
      <Navbar />
      <Account />
      <div className="bg-gray-50 fullfone">
        <div className="container-fluid bg-gray-50 w-3/4 min-h-screen konteineris ">
          <div className="content-center schollyearscreating ml-80">
            <h3 className="text-4xl font-bold">
                            Mokslo metų sukūrimas
            </h3>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 border border-blue-700 rounded buttonTeachers mt-6"
              onClick={addYear}
            >
                            Sukurti mokslo metus
            </button>
          </div>
          <div className="allschoolyears">
            <h3 className="text-2xl font-bold">Esami mokslo metai:</h3>
            <ul className="schollyearslist items-center">
              {years.length
                ? years.map((y) => (
                  <RenderYears
                    key={y.id}
                    id={y.id}
                    study_year={y.study_year}
                  />
                ))
                : null}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default SchoolYear;
