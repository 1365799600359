import React, { useContext, useReducer } from 'react';
import {
  getAllYears,
  chosenYears,
  currentYear
} from '../actions/YearsActions';
import YearsReducer from '../reducers/YearsReducer';
import {
  yearsStorage,
  chosenYearStorage,
  currentYearStorage,
  userStorage
} from './constants';
let initialState = {
  years: '',
  chosenYear: '',
  currentYear: ''
};
if (userStorage !== null) {
  initialState = {
    years: yearsStorage,
    chosenYear: chosenYearStorage,
    currentYear: currentYearStorage
  };
}
const YearsContext = React.createContext();

const YearsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(YearsReducer, initialState);

  const handleAllYears = (data) => {
    dispatch(getAllYears(data));
  };
  const handleChosenYear = (data) => {
    dispatch(chosenYears(data));
  };
  const handleCurrentYear = (data) => {
    dispatch(currentYear(data));
  };
  return (
    <YearsContext.Provider
      value={{
        ...state,
        handleAllYears,
        handleChosenYear,
        handleCurrentYear
      }}
    >
      {children}
    </YearsContext.Provider>
  );
};

export const useYearsContext = () => {
  return useContext(YearsContext);
};

export { YearsContext, YearsProvider };
