const ScrollTo = (top, left, behavior) => {
  return (
    window.scrollTo({
      top,
      left,
      behavior
    })
  );
};
export default ScrollTo;
