import Navbar from '../Navbar/Navbar';
import Account from '../Account/Account';
import React from 'react';
import AuthCheck from '../../services/auth/AuthCheck';
import PostAddDepartment from '../../services/post/PostAddDepartment';
import { useModulesContext } from '../../context/ModulesContext';
import PostDepartments from '../../services/post/PostDepartments';
import RenderDepartmentsByName from './RenderDepartmentsByName';
import { useUserContext } from '../../context/UserContext';

const DepartmentAdmin = () => {
  AuthCheck();
  const { departments, handleDepartments } = useModulesContext();
  const { errors, handleErrors } = useUserContext();

  //   PostDepartments(handleDepartments, '');
  const handleSubmitDepartment = (event) => {
    event.preventDefault();
    PostDepartments(handleDepartments, event.target.department.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    PostAddDepartment(event.target.department.value, handleErrors);
  };
  return (
    <>
      <Navbar />
      <Account />
      <div className="container-fluid bg-gray-50 flex justify-center ml-80 w-3/4 min-h-screen">
        <div className="mt-14"></div>
        <h3 className="text-4xl font-bold mudule-form ">
          Grupių administravimas
        </h3>
        <div className='mt-5 overflow-x-hidden shadow-md sm:rounded-lg'>
          <table className='w-full text-sm text-left text-gray-500 mr-7'>
            <thead className='text-xs uppercase bg-blue-500 text-white'>
              <tr>
                <td className='px-3 py-3'>Grupė</td>
                <td className='px-3 py-3'></td>
              </tr>
            </thead>
            <tbody className='text-center'>
              {departments
                ? departments.map((d) => (
                  <RenderDepartmentsByName
                    key={d.id}
                    id={d.id}
                    department={d.department}
                  />
                ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DepartmentAdmin;
