import React, { useEffect } from 'react';
import { useModulesContext } from '../../../context/ModulesContext';
import RenderTeacherModulesInactive from '../Renders/RenderTeacherModulesInactive';
import Spinner from '../../../misc/Spinner';
import API from '../../../API';
import { useYearsContext } from '../../../context/YearsContext';
import { useUpdateContext } from '../../../context/UpdateContext';
import Navbar from '../../Navbar/Navbar';
import Account from '../../Account/Account';
import AuthCheck from '../../../services/auth/AuthCheck';
import { useUserContext } from '../../../context/UserContext';
import { nanoid } from 'nanoid';
const ModuleSurvey = () => {
  AuthCheck();
  const { teacherModuleSubscriptions, handleAllTeacherModules, handleAllModules } = useModulesContext();
  const { updateState } = useUpdateContext();
  const { years, chosenYear, currentYear } = useYearsContext();
  const { user } = useUserContext();
  const chosenYearId = years.find(o => o.study_year === currentYear.studyYear);
  useEffect(() => {
    const result = years.find(o => o.study_year === chosenYear);
    if (user.roles.find(t => t.name === 'admin') || user.roles.find(t => t.name === 'darbuotojas')) {
      API.get('/users/module-subscriptions', {
        params: {
          study_year: result.study_year,
          per_page: 30
        }

      })
        .then(response => {
          handleAllModules(response.data);
        });
    }
    API.get('/user/module-subscriptions', {
      params: {
        study_year: result.study_year,
        per_page: 30
      }
    })
      .then(response => {
        handleAllTeacherModules(response.data);
      });
  }, [chosenYear, updateState, chosenYearId]);
  return (
    <>
      <Navbar />
      <Account />
      <div className="container-fluid bg-gray-50 ml-80 w-3/4 min-h-screen">
        <h3 className="text-4xl font-bold teacher-form">Modulių apklausos aktyvavimas</h3>
        <div className='mt-5 overflow-x-hidden shadow-md sm:rounded-lg'>
          <table className='w-full text-sm text-left text-gray-500 mr-7'>
            <thead className='text-xs uppercase bg-blue-500 text-white'>
              {teacherModuleSubscriptions?.data ?
              <tr>
                <td className='px-3 py-3'>Grupė</td>
                <td className='px-3 py-3'>Modulis</td>
                <td className='px-3 py-3'>Statusas</td>
                <td className='px-3 py-3 text-transparent'>_</td>
              </tr> : null }
            </thead>
            <tbody>
            {(teacherModuleSubscriptions?.data)
              ? teacherModuleSubscriptions.data.map(d =>
                <RenderTeacherModulesInactive key={d.id} id={d.id} department={d.department} module={d.module}
                surveyActive={d.surveyActive}/>
              )
              :
              <div className='m-1 p-1'>
                <img className='mx-auto' src="https://i.gifer.com/XOsX.gif" alt="Krovimo nuotrauka" />
                <p className='text-3xl'>Duomenys kraunami...</p>
                <p>Tai gali užtrukti iki 2 minučių!</p>
                <br />
                <p>Tuo tarpu galite pasivaikščioti arba pasidaryti kavos ☕</p>
              </div>
              }
            </tbody>
          </table>
        </div>

        {teacherModuleSubscriptions.data.length
          ? null

          : <div className="shadow-md px-8 pt-6 pb-8 mb-4 w-1/2 text-red-700 bg-red-100 rounded-lg">

            <h3 className="text-2xl font-bold flex justify-center">Šiais mokslo metais neturite modulių kuriems galite aktyvuoti apklausas.</h3>

          </div>}
      </div>
    </>
  );
};
export default ModuleSurvey;
