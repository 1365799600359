import { useModulesContext } from '../../context/ModulesContext';
import API from '../../API';
import { useEffect } from 'react';
import { useUserContext } from '../../context/UserContext';
const GetAllModules = (_yearId) => {
  const { handleAllModules, handleAllTeacherModules } = useModulesContext();
  const { user } = useUserContext();
  useEffect(() => {
    if (user.roles.find(t => t.name === 'admin') || user.roles.find(t => t.name === 'darbuotojas')) {
      API.get('/users/module-subscriptions', {
        params: {
          study_year: '2021-2022'
        }
      })
        .then(response => {
          handleAllModules(response.data);
        });
    }
    API.get('/user/module-subscriptions', {
      params: {
        study_year: '2021-2022'
      }
    })
      .then(response => {
        handleAllTeacherModules(response.data);
      });
  }, []);
};
export default GetAllModules;
