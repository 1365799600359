import React from 'react';
import RenderCheckboxOptions from '../Renders/RenderCheckboxOptions';
import { useModulesContext } from '../../../context/ModulesContext';
const ModuleSurveyForm = ({ id, question, answerOptions, answerType, multipleOptions }) => {
  const { checked } = useModulesContext();
  const textAreaPlaceholder = () => {
    if (checked) {
      if (checked[id - 1].answer) {
        return checked[id - 1].answer;
      }
    } else return 'Jūsų atsakymas';
  };
  const textAreaValue = () => {
    if (checked) {
      if (checked[id - 1].answer) {
        return checked[id - 1].answer;
      }
    }
  };
  return (
    <>
      <div className="m-3">
        <label className="block text-gray-700 text-2xl font-bold mb-3" htmlFor="answers">
          {id}. {question}
        </label>
        {answerOptions && answerOptions.length !== 0
          ? <>
            {answerOptions.map((d, index) =>
              <RenderCheckboxOptions key={d.id} id={d.id} value={d.value}
                answerType={answerType} multipleOptions={multipleOptions}
                answer={id} index={index}/>)}
          </>
          : <textarea
            className="
                                    form-control block w-full px-3 py-1.5
                                    text-base font-normal text-gray-700
                                    bg-white bg-clip-padding border border-solid border-gray-300
                                    rounded transition ease-in-out m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            rows="3"
            name="answer"
            placeholder={textAreaPlaceholder()}
            defaultValue={textAreaValue()}
          ></textarea>
        }

      </div>
    </>
  );
};
export default ModuleSurveyForm;
