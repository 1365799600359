// Type constants for users
export const ADD_TOKEN = 'ADD TOKEN';
export const ADD_USER = 'ADD USER';
export const ADD_EXPIRE = 'ADD EXPIRE';
export const GET_USERS = 'GET USERS';
export const GET_USER_BY_ROLE = 'GET USER BY ROLE';
// Type constants for years
export const GET_YEARS = 'GET YEARS';
export const CHOSEN_YEAR = 'CHOSEN YEAR';
export const CURRENT_YEAR = 'CURRENT YEAR';
// Type constants for ModulesSubscription
export const GET_DEPARTMENTS = 'GET DEPARTMENTS';
export const GET_MODULES = 'GET MODULES';
export const GET_TEACHERS = 'GET TEACHERS';
export const GET_ALL_MODULES = 'GET ALL MODULES';
export const GET_TEACHER_MODULES = 'GET TEACHER MODULES';
export const GET_SURVEY = 'GET SURVEY';
export const GET_TEACHER_SURVEY_ANSWERS = 'GET TEACHER SURVEY ANSWERS';
export const GET_TEACHER_SURVEY_ANSWERS_PAGINATE = 'GET TEACHER SURVEY ANSWERS PAGINATE';
export const SET_CHECKED = 'SET CHECKED';
export const GET_MODULES_SUBSCRIPTION = 'GET MODULES SUBSCRIPTION';
// Type constant for errors
export const ADD_ERRORS = 'ADD ERRORS';
// Type constants for Roles
export const GET_ROLES = 'GET ROLES';
// Type constant for detecting Updated state
export const SET_UPDATED = 'SET UPDATED';
