import { useUserContext } from '../../context/UserContext';
import RenderAllUsers from './RenderAllUsers';
import React, { useState, useEffect } from 'react';
import Pagination from '../../misc/Pagination';
import API from '../../API';
import './useradministration.css';
import { useUpdateContext } from '../../context/UpdateContext';
import Navbar from '../Navbar/Navbar';
import Account from '../Account/Account';
import AuthCheck from '../../services/auth/AuthCheck';
import PostGetUserByRole from '../../services/post/PostGetUserByRole';

const UserAdministration = () => {
  AuthCheck();
  const { allUsers, handleGetUsers, errors, handleGetUserByRole, userByRole } = useUserContext();
  const { updateState } = useUpdateContext();
  const [page, setPage] = useState(1);
  useEffect(() => {
    try {
      API.get(`/users?page=${page}`).then((response) => {
        handleGetUsers(response.data);
      });
    } catch (err) {
      console.log(err);
    }
  }, [page, errors, updateState]);

  const handleSubmitUser = (event) => {
    event.preventDefault();
    if (event.target.name.value === '') {
      API.get(`/users?page=${page}`)
        .then(response => {
          handleGetUsers(response.data);
          handleGetUserByRole('');
        });
    } else {
      PostGetUserByRole(handleGetUserByRole, event.target.name.value);
    }
  };
  return (
    <>
      <Navbar />
      <Account />
      <div className="container-fluid bg-gray-50 flex justify-center ml-80 w-3/4 min-h-screen">
        <h3 className="text-4xl font-bold teacher-form">Vartotojų administravimas</h3>

        <form
          onSubmit={handleSubmitUser}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mudule-form"
        >
          <div>
            <label className="block text-gray-700 text-2xl font-bold mb-3 my-3" htmlFor="name">
                            Vartotojo paieška:
            </label>
            <input
              type="text"
              multiple
              name="name"
              className="form-select form-select-sm
                                        appearance-none
                                        block
                                        w-full
                                        px-2
                                        py-2
                                        text-sm
                                        font-normal
                                        text-gray-700
                                        bg-white bg-clip-padding bg-no-repeat
                                        border border-solid border-gray-300
                                        rounded
                                        transition
                                        ease-in-out
                                        m-0
                                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              placeholder="Vartotojo vardas"
            />
          </div>
          <div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white w-38 font-bold py-2 px-4 rounded mt-5"
            >
                            Ieškoti
            </button>
          </div>
        </form>

        <div className="overflow-x-hidden shadow-md sm:rounded-lg ">
          {allUsers && allUsers.data.length
            ? <table className="w-full text-sm text-left text-gray-500 mr-7">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 bg-blue-500 text-white">
                <tr>
                  <th scope="col" className="px-3 py-3">
                                    Vardas
                  </th>
                  <th scope="col" className="px-3 py-3">
                                    El.Paštas
                  </th>
                  <th scope="col" className="px-3 py-3">
                                    Skyrius
                  </th>
                  <th scope="col" className="px-6 py-3">
                                    Rolių kontrolė
                  </th>
                  <th scope="col" className="px-3 py-3">
                                    Rolių pridėjimas
                  </th>
                </tr>
              </thead>
              <tbody>
                {userByRole.data
                  ? userByRole.data.map((d) => (
                    <RenderAllUsers
                      key={d.id}
                      id={d.id}
                      fullName={d.fullName}
                      email={d.email}
                      department={d.department}
                      roles={d.roles}
                    />
                  ))
                  : allUsers.data.map((d) => (
                    <RenderAllUsers
                      key={d.id}
                      id={d.id}
                      fullName={d.fullName}
                      email={d.email}
                      department={d.department}
                      roles={d.roles}
                    />))}
              </tbody>
            </table>
            : <div className="bg-white shadow-md rounded px-28 pt-6 pb-8 text-red-700 bg-red-100 rounded-lg">
              <h3 className="text-2xl font-bold flex justify-center">Pasirinkto vartotojo duomenų bazėje nėra</h3>
            </div>
          }
        </div>
        <div>
          <div className="ml-16">
            {userByRole
              ? null
              : allUsers
                ? <Pagination
                  page={page}
                  setPage={setPage}
                  lastPage={allUsers.meta.last_page}
                  step={5}
                />
                : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default UserAdministration;
