import API from '../../API';

const PutModuleUpdate = (moduleId, moduleName, handleUpdateState) => {
  return (
    API.put(`/modules/${moduleId}`, {
      moduleName
    })
      .then(resp => {
        console.log(resp);
        handleUpdateState(`updated ${moduleId} to ${moduleName}`);
      })
  );
};
export default PutModuleUpdate;
