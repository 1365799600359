import { useEffect } from 'react';
import API from '../../API';
import { useYearsContext } from '../../context/YearsContext';

const GetYears = (updateState) => {
  const { handleAllYears, handleChosenYear } = useYearsContext();
  useEffect(() => {
    API.get('/get-all-years')
      .then(response => {
        handleAllYears(response.data);
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].active === 1) {
            handleChosenYear(response.data[i].study_year);
          }
        }
      });
  }, [updateState]);
};
export default GetYears;
