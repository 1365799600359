import API from '../../API';
const PostLogout = () => {
  return (
    API.post('/logout')
      .then((response) => {
        console.log(response?.data?.success);
      })
      .then(() => {
        localStorage.clear();
      })
      .then(() => (window.location.pathname = '/'))
  );
};
export default PostLogout;
