import Navbar from '../Navbar/Navbar';
import Account from '../Account/Account';
import { useModulesContext } from '../../context/ModulesContext';
import API from '../../API';
import { useYearsContext } from '../../context/YearsContext';
import React, { useEffect, useState } from 'react';
import { useUserContext } from '../../context/UserContext';
import { MultiSelect } from 'react-multi-select-component';
import AuthCheck from '../../services/auth/AuthCheck';
import APIMultipart from '../../APIMultipart';
const SurveyImport = () => {
  AuthCheck();
  const { teachers } = useModulesContext();
  const { years, chosenYear, currentYear } = useYearsContext();
  const [selectedTeacher, setSelectedTeacher] = useState([]);
  const { errors, handleErrors } = useUserContext();
  const [modules, setModules] = useState([]);
  const [file, setSelectedFile] = useState();
  let chosenYearId = { study_year: years.find(o => o.study_year === chosenYear).study_year };
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  useEffect(() => {
    handleErrors('');
  }, []);
  useEffect(() => {
    let chosenYearId = years.find(o => o.study_year === currentYear.studyYear);
    if (chosenYear !== '' && chosenYear !== null) {
      chosenYearId = years.find(o => o.study_year === chosenYear);
    }
    try {
      API.get('/users/module-subscriptions?page=1', {
        params: {
          study_year: chosenYearId.study_year,
          per_page: 999
        }

      })
        .then(response => {
          setModules(response.data.data);
        });
    } catch (err) {
      console.log(err);
    }
  }, [chosenYear]);
  async function handleSubmit (event) {
    event.preventDefault();
    const allTeachers = [];
    for (let i = 0; i < selectedTeacher.length; i++) {
      allTeachers.push(teachers.find(t => t.fullName === selectedTeacher[i].value).id);
    }
    if (event.target.years.value !== '') {
      chosenYearId = { study_year: event.target.years.value };
    }
    const formData = new FormData();
    formData.append('module_name', event.target.module_name.value);
    formData.append('study_year', chosenYearId.study_year);
    for (let i = 0; i < allTeachers.length; i++) {
      formData.append('teachers[]', allTeachers[i]);
    }
    formData.append('answers', file);
    try {
      const response = await APIMultipart({
        method: 'post',
        url: '/survey/module/old-answers-import',
        data: formData
      }).then(resp => {
        console.log(resp);
        if (resp.errors) {
          handleErrors(resp.errors);
        } else {
          handleErrors('');
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  const answerErrors = (data) => {
    return (
      <>
        <div>{data[0]}</div>
        <div>{data[1]}</div>
        <div>{data[2]}</div>
      </>
    );
  };
  return (
    <>
      <Navbar/>
      <Account/>
      <div className="container-fluid bg-gray-50 ml-80 w-3/4 min-h-screen">
        <h3 className="text-4xl font-bold teacher-form">Apklausų importavimas</h3>
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mudule-form">
          <div className="createModule xl:w-96 mb-6">
            <div>
              <label className="block text-gray-700 text-2xl font-bold mb-3" htmlFor="users">
                                Pasirinkite mokytoją
              </label>
              <MultiSelect
                type="text"
                id="users"
                multiple="multiple"
                options={teachers.map(o => { return { label: o.fullName, value: o.fullName }; })}
                value={selectedTeacher}
                onChange={setSelectedTeacher}
                displayValue="users"
                labelledBy="users"
                className="form-select form-select-sm
                                        appearance-none
                                        w-full
                                        text-sm
                                        font-normal
                                        text-gray-700
                                        bg-white bg-clip-padding bg-no-repeat
                                        rounded
                                        transition
                                        ease-in-out
                                        m-0
                                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"

              />
              {errors.teachers
                ? <div
                  className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                  role="alert"><span className="font-medium">{errors.teachers}</span>
                </div>
                : null}
            </div>
            <div>
              <label
                className="block text-gray-700 text-2xl font-bold mb-3 my-3"
                htmlFor="module_name"
              >
                                Modulio pavadinimas
              </label>
              <input
                type="text"
                name="module_name"
                list="modules"
                className="form-select form-select-sm
                                            appearance-none
                                            block
                                            w-full
                                            px-2
                                            py-2
                                            text-sm
                                            font-normal
                                            text-gray-700
                                            bg-white bg-clip-padding bg-no-repeat
                                            border border-solid border-gray-300
                                            rounded
                                            transition
                                            ease-in-out
                                            m-0
                                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="Modulis"
              ></input>
              {errors.module_name
                ? <div
                  className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                  role="alert"><span className="font-medium">{errors.module_name}</span>
                </div>
                : null}
            </div>
            <div>
              <label
                className="block text-gray-700 text-2xl font-bold mb-3 my-3"
                htmlFor="file"
              >
                                Pasirinkti metus
              </label>
              <input
                type="text"
                name="years"
                aria-label="Pasirinkti metus"
                className="form-select form-select-sm
                                            appearance-none
                                            block
                                            w-full
                                            px-2
                                            py-2
                                            text-sm
                                            font-normal
                                            text-gray-700
                                            bg-white bg-clip-padding bg-no-repeat
                                            border border-solid border-gray-300
                                            rounded
                                            transition
                                            ease-in-out
                                            m-0
                                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder={`${chosenYear}`}
              ></input>
              {errors.study_year
                ? <div
                  className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                  role="alert"><span className="font-medium">{errors.study_year}</span>
                </div>
                : null}
            </div>
            <div>
              <label
                className="block text-gray-700 text-2xl font-bold mb-3 my-3"
                htmlFor="file"
              >
                                Pasirinkti failą
              </label>
              <input
                id="file"
                type="file"
                name="file"
                aria-label="Pasirinkti faila"
                onChange={changeHandler}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx, .xls"
                multiple
                className="form-select form-select-sm
                                            appearance-none
                                            block
                                            w-full
                                            px-2
                                            py-2
                                            text-sm
                                            font-normal
                                            text-gray-700
                                            bg-white bg-clip-padding bg-no-repeat
                                            border border-solid border-gray-300
                                            rounded
                                            transition
                                            ease-in-out
                                            m-0
                                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              ></input>
              {errors.answers
                ? <div
                  className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                  role="alert"><span className="font-medium">{answerErrors(errors.answers)}</span>
                </div>
                : null}
            </div>
            <div className="">
              <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white w-36 font-bold py-2 px-4 rounded mt-5">
                                Importuoti
              </button>
            </div>
          </div>

        </form>
      </div>
    </>
  );
};
export default SurveyImport;
