import {
  SET_UPDATED
} from '../actions/types';
const UpdateReducer = (state, action) => {
  switch (action.type) {
  case SET_UPDATED:
    return {
      ...state,
      updateState: action.payload
    };
  default:
    return state;
  }
};

export default UpdateReducer;
