import React from 'react';

const RenderAnswersOptions = (props) => {
  return (
    <>
      {props.count > 0 && props.id >= 6
        ? <div style={{ color: 'red' }} className="text-xl">
          {props.value}
        </div>
        : null}
    </>
  );
};
export default RenderAnswersOptions;
