import './SchoolYear.css';
import { useYearsContext } from '../../context/YearsContext';
import PostDeleteYear from '../../services/post/PostDeleteYear';
import { useUpdateContext } from '../../context/UpdateContext';
import GetYears from '../../services/get/GetYears';
import React, { useState } from 'react';
import Modal from '../../misc/Modal';
const RenderYears = (props) => {
  const { handleChosenYear } = useYearsContext();
  const { handleUpdateState } = useUpdateContext();
  const { updateState } = useUpdateContext();
  const [showModal, setShowModal] = useState(false);
  const [modalOptions, setModalOptions] = useState({});
  const handleModalOptions = () => {
    setShowModal(true);
    setModalOptions({
      text: `Ar tikrai norite ištrinti ${props.study_year} mokslo metus`,
      btnText: 'Ištrinti'
    });
  };
  const AddYear = (data) => () => {
    handleChosenYear(data);
  };
  const deleteYear = () => {
    handleUpdateState(`deleted year:${props.id}`);
    setShowModal(false);
    PostDeleteYear(props.id);
  };
  GetYears(updateState);
  return (
    <div>
      <li className="inline-flex">
        <a
          href="/autentifikuota/mokslo-metai"
          onClick={AddYear(props.study_year)}
          className="flex"
        >
          <p className="text-2xl">{props.study_year}</p>

        </a>
        <a
          onClick={handleModalOptions}
          href="#">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1 deleteiconcolor" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>

        </a>
      </li>
      {showModal
        ? (
          <Modal setShowModal={setShowModal} confirm={deleteYear} text={modalOptions.text} btnText={modalOptions.btnText}/>
        )
        : null}
    </div>

  );
};
export default RenderYears;
