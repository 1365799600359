import { toast } from 'react-toastify';
import axios from 'axios';

const APIUrl = (window.location.hostname === 'localhost') ? 'http://kus-backend.ddev.site/api' : 'https://api-kus.kitm.lt/api';

const API = axios.create({
  baseURL: APIUrl,
  // baseURL: "https://api-kus.kitm.lt/api",
  // baseURL: "http://kus-backend.ddev.site/api",
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('token'),
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json'
  }
});
API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
API.interceptors.response.use(
  (response) => {
    if (response.data.warning) {
      toast.warn(response.data.warning, { theme: 'colored' });
    } else if (response.data.success) {
      toast.success(response.data.success, { theme: 'colored' });
    } else if (response.data.error) {
      toast.error(response.data.error, { theme: 'colored' });
    }
    return response;
  },
  function (error) {
    if (error.response === 401) {
      toast.error('Noredami naudotis KUS, prisijunkite', { theme: 'colored' });
    }
    return error.response.data;
  }
);
export default API;
