import React from 'react';

const RenderRolesOptions = (props) => {
  return (
    <>
      <option value={props.id}> {props.name}</option>
    </>
  );
};
export default RenderRolesOptions;
