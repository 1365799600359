import React, { useContext, useReducer } from 'react';
import {
  setUpdated
} from '../actions/UpdateActions';
import UpdateReducer from '../reducers/UpdateReducer';

const initialState = {
  updateState: ''
};
const UpdateContext = React.createContext();

const UpdateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UpdateReducer, initialState);

  const handleUpdateState = (state) => {
    dispatch(setUpdated(state));
  };
  return (
    <UpdateContext.Provider
      value={{ ...state, handleUpdateState }}
    >
      {children}
    </UpdateContext.Provider>
  );
};

export const useUpdateContext = () => {
  return useContext(UpdateContext);
};

export { UpdateContext, UpdateProvider };
