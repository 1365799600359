import AuthCheck from '../../../services/auth/AuthCheck';
import Navbar from '../../Navbar/Navbar';
import Account from '../../Account/Account';
import React, { useEffect, useState } from 'react';
import { useModulesContext } from '../../../context/ModulesContext';
import RenderTeacherModulesForForm from '../Renders/RenderTeacherModulesForForm';
import Spinner from '../../../misc/Spinner';
import ModuleSurveyForm from './ModuleSurveyForm';
import { nanoid } from 'nanoid';
import API from '../../../API';
import { useUpdateContext } from '../../../context/UpdateContext';
import { useUserContext } from '../../../context/UserContext';
import RenderErrors from '../Renders/RenderErrors';
import ScrollTo from '../../../misc/ScrollTo';

const ModuleSelect = () => {
  AuthCheck();
  const { handleErrors, errors } = useUserContext();
  const { teacherModuleSubscriptions, survey, handleGetSurvey, handleAllTeacherModules, handleGetChecked } = useModulesContext();
  const { updateState, handleUpdateState } = useUpdateContext();
  const [checkedTooMany, setError] = useState('');
  let error = '';
  useEffect(() => {
    API.get('/student/module-subscriptions')
      .then(resp => {
        handleAllTeacherModules(resp);
      });
  }, [updateState]);
  console.log(teacherModuleSubscriptions);
  console.log(teacherModuleSubscriptions?.data[0])

  if (teacherModuleSubscriptions?.message === 'Unauthenticated.') {
    localStorage.clear();
    window.location.pathname = '/';
  }

  // console.log(survey.questions);
  const splitKeyValue = obj => {
    const keys = Object.keys(obj);
    const res = [];
    for (let i = 0; i < keys.length / 2; i++) {
      res.push({
        id: Math.floor(keys[i].substring(7, 8)),
        question: survey.questions[Math.floor(keys[i].substring(7, 8))].question,
        error: obj[keys[i]]
      });
    };
    return res;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    error = '';
    const surveyObj = [];
    let answers = [];

    for (let j = 0; j < survey.questions.length; j++) {
      answers = [];

      if (survey.questions[j].answerOptions !== null && survey.questions[j].answerOptions.length > 0 && survey.questions[j].answerOptions.length < 6) {
        // 1-5 questions
        
        // console.log(survey.questions[j].answerOptions);
        // console.log(event.target);
        for (let i = j * 5; i < j * 5 + 5; i++) {
          let answer = event.target[i];

          if (i > 39) answer = event.target[i+2];

          // console.log(1, i, answer, answer.checked, event.target[i+1].checked, Math.floor(answer.value));
          
          // console.log('answer', answer.name, answer.value, answer.checked);
          // console.log(event.target.answerId[i], event.target.answerId[i].checked, event.target.answerId[i].value);
          
          // if (answer.checked) {
          //   answers.push(Math.floor(answer.value));
          // }
          if (answer.checked !== false && Math.floor(answer.value) < 11 && Math.floor(answer.value) > 5) {
            answers.push(Math.floor(answer.value));
          } else if (answer.checked !== false && Math.floor(answer.value) <= 5) {
            answers.push(Math.floor(answer.value));
          } else if (i === answer.length - 2 && event.target[i + 1].checked !== false && Math.floor(event.target[i + 1].value) < 11 && Math.floor(event.target[+1].value)) {
            // console.log(2, i, answer, answer.checked, event.target[i+1].checked, Math.floor(answer.value));
            answers.push(Math.floor(event.target[i + 2].value));
          }
        }
        // console.log('answers', answers);
        if (answers.length > 1) {
          error = 'Klausimui 1, 2, 3, 4, 5, 6, 9 galima pasirinkti tik vieną laukelį';
        }
        surveyObj.push(
          {
            survey_question_id: j + 1,
            answer: null,
            answer_option_id: answers
          }
        );
      } else if (survey.questions[j].answerOptions !== null && survey.questions[j].answerOptions.length > 5) {
        // Checkbox question
        
        // console.log(survey.questions[j].answerOptions);
        
        for (let i = j * 5; i < j * 5 + survey.questions[j].answerOptions.length; i++) {
          let answer = event.target[i];
          // console.log(i, answer);
          // console.log(event.target.answerId[i], event.target.answerId[i].checked, event.target.answerId[i].value);
        
          if (answer.checked) {
            answers.push(Math.floor(answer.value));
          }
        }
        surveyObj.push(
          {
            survey_question_id: j + 1,
            answer: null,
            answer_option_id: answers
          }
        );
      } else {
        // Textarea question
        // console.log(j+1, event.target.answer);
        surveyObj.push(
          {
            survey_question_id: j + 1,
            answer: event.target.answer.value,
            answer_option_id: null
          }
        );
      }
    }
    handleGetChecked(surveyObj);
    if (error === '') {
      // console.log({
      //   errors: errors,
      //   error: error,
      //   surveyObj: surveyObj,
      //   updateState: updateState
      // });
      API.post(`/survey/module-subscription/${updateState}`, {
        survey: JSON.stringify(surveyObj)
      }).then(response => {
        if (response.errors) {
          console.error(response.errors);
          handleErrors(splitKeyValue(response.errors));
          ScrollTo(0, 0, 'smooth');
        } else {
          handleErrors('');
          handleGetSurvey('');
          setError('');
          handleGetChecked('');
          handleUpdateState(`submit survey: ${survey.name}`);
          ScrollTo(0, 0, 'auto');
        }
      });
    } else {
      setError('Klausimui 1, 2, 3, 4, 5, 6, 9 galima pasirinkti tik vieną laukelį');
      ScrollTo(0, 0, 'auto');
    }
  };
  return (
    <>
      <Navbar />
      <Account />
      <div className="container-fluid bg-gray-50 ml-80 w-3/4 min-h-screen">
        <h3 className="text-4xl font-bold mt-60">Modulių apklausos</h3>
        {survey === ''
          ? <div className="survey-modules flex flex-col items-center w-8/12 justify-center">
            {(teacherModuleSubscriptions !== null && teacherModuleSubscriptions.data !== null)
              ? teacherModuleSubscriptions.data.map((d) =>
                <RenderTeacherModulesForForm key={d.id} id={d.id} teachers={d.teachers} module={d.module}
                  surveyActive={d.survey_active} answers={d.my_answers}/>
              )
              : <Spinner/>}
          </div>
          : null}
        {survey.questions
          ? <form onSubmit={handleSubmit} className="bg-white shadow-md w-7/12 mr-16 rounded px-12 pt-6 pb-8 mb-4 mt-12">
            {errors
              ? <>
                {errors
                  ? errors.map(e => <RenderErrors key={e.id} id={e.id} question={e.question} error={e.error}/>)
                  : null}
              </>
              : null}
            {checkedTooMany !== ''
              ? <div
                className="p-2.5 mb-1 text-sm text-red-700 w-full bg-red-100 rounded-lg"
                role="alert"><span className="font-medium"><h3 className="text-xl">{checkedTooMany}</h3></span>
              </div>
              : null}
            <h3 className="text-4xl font-bold teacher-form capitalize p-8">{survey.name}</h3>
            {survey.questions.map((d) => <ModuleSurveyForm key={nanoid()} id={d.id} question={d.question} answerOptions={d.answerOptions}
              answerType={d.answerType} multipleOptions={d.multipleOptions}/>)}
            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white xl:w-1/6 font-bold py-2 px-4 rounded mt-5">
                            Pateikti
            </button>
          </form>
          : null}
      </div>
    </>
  );
};
export default ModuleSelect;
