import API from '../../API';
const PostAddModule = (handleAddModule, handleErrors, teachers, department, moduleId) => {
  const teachersIds = teachers.map(s => s.id);
  return (
    API.post('/module-subscriptions', {
      module_id: moduleId,
      department,
      teachers: teachersIds
    }).then(response => {
      console.log(response);
      if (response.errors) {
        handleErrors(response.errors);
      } else {
        handleErrors('');
      }
    })
  );
};
export default PostAddModule;
