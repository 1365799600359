import { useUserContext } from '../../context/UserContext';
import React from 'react';

const AuthCheck = () => {
  const { user } = useUserContext();
  return (
    <>
      {
        setTimeout(function () {
          if (!user) {
            window.location.pathname = '/';
          }
        }, 5000)
      }

    </>

  );
};
export default AuthCheck;
