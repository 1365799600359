import kitm3 from '../kitm3.png';
import './login.css';
import React from 'react';
const Login = () => {
  window.onload = () => {
    localStorage.clear();
  };

  const APIUrl = (window.location.hostname === 'localhost') ? 'http://kus-backend.ddev.site/api/redirect/' : 'https://api-kus.kitm.lt/api/redirect/';
  return (
    <>
      <div className="login">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-lg-5 col-12">
            <div className="login-left">
              <div className="login-logo">
                <a href="/"><img src={kitm3} alt="Logo" /></a>
              </div>
              <h1 className="login-title">Prisijungimas</h1>
              <p className="login-subtitle mb-5">Prisijunkite, su savo Office 365 duomenimis.</p>
              {/* <a href="http://kus-backend.ddev.site/api/redirect/" className="bg-blue-500 hover:bg-blue-00 text-white font-bold py-3 px-24 border-b-4 border-blue-700 hover:border-blue-500 rounded buttonas">Prisijungti</a> */}
              {/* <a href="https://api-kus.kitm.lt/api/redirect/" className="bg-blue-500 hover:bg-blue-00 text-white font-bold py-3 px-24 border-b-4 border-blue-700 hover:border-blue-500 rounded buttonas">Prisijungti</a> */}

              <a href={APIUrl} className="bg-blue-500 hover:bg-blue-00 text-white font-bold py-3 px-24 border-b-4 border-blue-700 hover:border-blue-500 rounded buttonas">Prisijungti</a>
            </div>
          </div>
          <div className="login-right">
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
