import { useUserContext } from '../../context/UserContext';
import { useEffect } from 'react';
import API from '../../API';
import { useYearsContext } from '../../context/YearsContext';
const PostCallback = () => {
  const { handleAddToken, handleExpire } = useUserContext();
  const { handleAddUser } = useUserContext();
  const { handleCurrentYear } = useYearsContext();
  useEffect(() => {
    localStorage.setItem('auth', 'true');
    const url = new URL(window.location.href);
    const code = url.searchParams.get('code');
    // TODO: change to async
    API.post(`/login?code=${code}`)
      .then((response) => {
        handleAddToken(response.data.token);
        handleExpire(response.data.expiresAt);
      })
      .then(async () => {
        const userResponse = await API.get('/user');
        handleAddUser(userResponse.data);
      })
      .then(() => {
        API.get('/get-current-year').then((response) => {
          handleCurrentYear(response.data);
          window.location = '/autentifikuota';
        });
      });
  }, []);
  return null;
};
export default PostCallback;
