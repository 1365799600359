import {
  ADD_TOKEN,
  ADD_USER,
  GET_USERS,
  GET_ROLES,
  GET_USER_BY_ROLE,
  ADD_ERRORS,
  ADD_EXPIRE
} from '../actions/types';
const UserReducer = (state, action) => {
  switch (action.type) {
  case ADD_TOKEN:
    return {
      ...state,
      token: action.payload
    };
  case ADD_USER:
    return {
      ...state,
      user: action.payload
    };
  case ADD_ERRORS:
    return {
      ...state,
      errors: action.payload
    };
  case ADD_EXPIRE:
    return {
      ...state,
      expire: action.payload
    };
  case GET_USERS:
    return {
      ...state,
      allUsers: action.payload
    };
  case GET_ROLES:
    return {
      ...state,
      roles: action.payload
    };
  case GET_USER_BY_ROLE:
    return {
      ...state,
      userByRole: action.payload
    };
  default:
    return state;
  }
};
export default UserReducer;
