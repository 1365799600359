import { useEffect } from 'react';
import API from '../../API';
import { useUserContext } from '../../context/UserContext';

const GetRoles = () => {
  const { handleGetRoles } = useUserContext();
  useEffect(() => {
    API.get('/roles')
      .then(response => {
        handleGetRoles(response.data);
      });
  }, []);
};
export default GetRoles;
