import React from 'react';

const RenderModules = (props) => {
  return (
    <>
      {props.surveyActive === '1'
        ? <option value={props.module}>{props.module}</option>
        : null
      }
    </>
  );
};
export default RenderModules;
