import React, { useContext, useReducer } from 'react';
import {
  getDepartments,
  getModules,
  getAllModules,
  getTeacherModules,
  getTeachers,
  getSurvey,
  getTeacherSurveyAnswers,
  getTeacherSurveyAnswersPaginate,
  setChecked, getModulesSubscription
} from '../actions/ModulesActions';
import ModulesReducer from '../reducers/ModulesReducer';
import {
  departmentsStorage,
  allModulesStorageSubscription,
  getTeacherModulesStorage,
  teachersStorage,
  allModulesStorage
} from './constants';
import PropTypes from 'prop-types';
const initialState = {
  departments: departmentsStorage,
  modulesByName: [],
  moduleSubscriptions: allModulesStorageSubscription,
  teacherModuleSubscriptions: getTeacherModulesStorage,
  teachers: teachersStorage,
  survey: '',
  teacherSurveyAnswers: '',
  teacherSurveyAnswersPaginate: '',
  checked: '',
  allModules: allModulesStorage
};
const ModulesContext = React.createContext();

const ModulesProvider = ({ children }) => {
  ModulesProvider.propTypes = {
    children: PropTypes.object
  };

  const [state, dispatch] = useReducer(ModulesReducer, initialState);

  const handleDepartments = (data) => {
    dispatch(getDepartments(data));
  };
  const handleGetModules = (data) => {
    dispatch(getModules(data));
  };
  const handleAllModules = (data) => {
    console.log('handleAllModules', data, state);
    dispatch(getModulesSubscription(data));
  };
  const handleAllTeacherModules = (data) => {
    dispatch(getTeacherModules(data));
  };
  const handleGetTeachers = (data) => {
    dispatch(getTeachers(data));
  };
  const handleGetSurvey = (data) => {
    dispatch(getSurvey(data));
  };
  const handleGetTeacherSurveyAnswers = (data) => {
    dispatch(getTeacherSurveyAnswers(data));
  };
  const handleGetTeacherSurveyAnswersPaginate = (data) => {
    dispatch(getTeacherSurveyAnswersPaginate(data));
  };
  const handleGetChecked = (data) => {
    dispatch(setChecked(data));
  };
  const handleModules = (data) => {
    dispatch(getAllModules(data));
  };
  return (
    <ModulesContext.Provider
      value={{
        ...state,
        handleDepartments,
        handleGetModules,
        handleAllModules,
        handleAllTeacherModules,
        handleGetTeachers,
        handleGetSurvey,
        handleGetTeacherSurveyAnswers,
        handleGetTeacherSurveyAnswersPaginate,
        handleGetChecked,
        handleModules
      }}
    >
      {children}
    </ModulesContext.Provider>
  );
};

export const useModulesContext = () => {
  return useContext(ModulesContext);
};

export { ModulesContext, ModulesProvider };
