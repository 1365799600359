import React from 'react';

const RenderDepartmentsByName = (props) => {
  return (
    <>
      <tr className="bg-white border-b">
        <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap flex flex-wrap w-72">
          {props.department}
        </td>
        <td>
          <button disabled>Pašalinti</button>
        </td>
      </tr>
    </>
  );
};
export default RenderDepartmentsByName;
