import React, { useEffect, useState } from 'react';
import '../index.css';
import Navbar from '../../Navbar/Navbar';
import Account from '../../Account/Account';
import AuthCheck from '../../../services/auth/AuthCheck';
import { nanoid } from 'nanoid';
import { useYearsContext } from '../../../context/YearsContext';
import API from '../../../API';
const ActivatedModuleSubscriptions = () => {
  AuthCheck();
  const [subscriptions, setSubscriptions] = useState([]);

  // const {teacher_module_subscriptions, handleAllTeacherModules} = useModulesContext()
  const { years, chosenYear, currentYear } = useYearsContext();

  const chosenYearId = years.find(o => o.study_year === currentYear.studyYear);
  useEffect(() => {
    const result = years.find(o => o.study_year === chosenYear);

    try {
      API.get(`/survey/teacher/${result.id}/count2`)
        .then(subscriptionsResponse => {
          // For debugging only
          // console.log('sub_response', subscriptionsResponse.data);
          setSubscriptions(subscriptionsResponse.data);
        });
    } catch (error) {
      console.error(error);
    }
  }, [chosenYear, chosenYearId, years]);

  // console.log(teacher_module_subscriptions);

  return (
    <>
      <Navbar />
      <Account />

      <div className="container-fluid bg-gray-50 ml-80 w-3/4 min-h-screen">
        <h3 className="text-4xl font-bold mudule-form">Apklausų pildymas</h3>
        <div className='mt-5 overflow-x-hidden shadow-md sm:rounded-lg'>
          <table className='w-full text-sm text-left text-gray-500 mr-7'>
            <thead className='text-xs uppercase bg-blue-500 text-white'>
              {subscriptions?.data ?
              <tr>
                <td className='px-3 py-3'>Mokytojas/Modulis (departamentas)</td>
                <td className='px-3 py-3'>Apklausa aktyvuota</td>
                <td className='px-3 py-3'>Užpildyta</td>
              </tr> : null }
            </thead>
            <tbody>
              {subscriptions?.data
                ? Object.keys(subscriptions.data).map((i, indexx) => <><tr key={nanoid()} className="bg-white border-b">
                  <td colSpan={2} className="px-3 py-4 font-medium text-gray-900 flex flex-wrap w-72">{subscriptions.data[i].user}</td>
                </tr>
                {subscriptions.data[i].modules.map((module, index) => <tr key={index} className='bg-gray-800 text-white'>
                  <td className='px-9 py-2'>{module.module} ({module.department})</td>
                  <td className='px-9 py-2'>{(module.active === 1) ? '✅' : '❌'}</td>
                  <td className='px-9 py-2'>{(module.active === 1) ? module.answers : ''}</td>
                </tr> ) }</>)
                : <div className='m-1 p-1'>
                    <img className='mx-auto' src="https://i.gifer.com/XOsX.gif" alt="Krovimo nuotrauka" />
                    <p className='text-3xl'>Duomenys kraunami...</p>
                    <p>Tai gali užtrukti iki 2 minučių!</p>
                    <br />
                    <p>Tuo tarpu galite pasivaikščioti arba pasidaryti kavos ☕</p>
                  </div>}
            </tbody>
          </table>
        </div>

      {/* <tr className="bg-white border-b">
        <td className="px-6 py-4 font-medium text-gray-900">
          {props.module}
        </td>
        <td className="px-9 py-4">
          {props.studyYear}
        </td>
        <td className="px-9 py-4">
          {props.department}
        </td>
        <td className='px-9 py-4'>
          <button onClick={handleDeletion}>Šalinti prenumeratą</button>
        </td>
      </tr>
      {props.teachers.map((teacher, index) =>
        <tr key={index} className='bg-gray-800 text-white'>
          <td colSpan={3} className='px-9 py-2'>{teacher.full_name}</td>
          <td className='px-9 py-2'>
            <button onClick={() => handleTeacherDeletion(index)}>Šalinti mokytoją iš prenumeratos</button>
          </td>
        </tr>
      )} */}
      </div>
    </>
  );
};
export default ActivatedModuleSubscriptions;
