import RenderAnswersOptions from './RenderAnswersOptions';
import { nanoid } from 'nanoid';
import React from 'react';
const RenderAnswers = (props) => {
  return (
    <>
      <div>
        <label className="text-gray-700 text-xl font-bold" >
          {props.question}
        </label>
        <div style={{ color: 'red' }} className="text-xl">{props.questionAverage}</div>
        {props.answers != null

          ? <div style={{ color: 'red' }} className="text-xl">{props.answers}</div>
          : null}
        {props.answer_options != null
          ? <>
            {props.answer_options.map(d => <RenderAnswersOptions key={nanoid()} id={d.id} value={d.value} count={d.count}/>)}
          </>
          : null}
      </div>
    </>
  );
};
export default RenderAnswers;
