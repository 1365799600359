import API from '../../API';

const PostAddRoles = (handleUpdateState, roleId, userId, handleGetUserByRole) => {
  return (
    API.post(`user/add-role/${userId}`, {
      role_id: roleId
    }).then(response => {
      console.log(response);
      if (!response.data.warning) {
        handleUpdateState(`added role for:${userId} role added: ${roleId}`);
        handleGetUserByRole('');
      }
    })
  );
};
export default PostAddRoles;
