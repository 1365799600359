import PutModuleUpdate from '../../../services/put/PutModuleUpdate';
import { useUpdateContext } from '../../../context/UpdateContext';
import React, { useState } from 'react';
import PostDeleteModule from '../../../services/post/PostDeleteModule';
const RenderAllModulesForUpdate = (props) => {
  const { handleUpdateState } = useUpdateContext();
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const toggleAdvancedOpen = (event) => {
    event.preventDefault();

    setIsAdvancedOpen((currentState) => !currentState);
  };
  const handleUpdate = (event) => {
    event.preventDefault();
    PutModuleUpdate(props.id, event.target.name.value, handleUpdateState);
  };
  const handleDelete = () => {
    PostDeleteModule(props.id, handleUpdateState);
  };
  return (
    <tr className="bg-white border-b">
      <td className="px-3 py-4 font-medium text-gray-900 flex flex-wrap">
        {props.name}
      </td>
      <td>
        <div className="flex flex-row">
          <button onClick={toggleAdvancedOpen}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 updateiconcolor"
              fill="none"
              viewBox="0 0 24 20"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
          </button>
          <button onClick={handleDelete}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 deleteiconcolor"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          {isAdvancedOpen && (
            <form onSubmit={handleUpdate}>
              <input
                name="name"
                className="form-select form-select-sm
                                        appearance-none
                                        w-full
                                        text-sm
                                        font-normal
                                        text-gray-700
                                        bg-white bg-clip-padding bg-no-repeat
                                        rounded
                                        transition
                                        ease-in-out
                                        m-0
                                        h-10
                                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none border-2"
              ></input>
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                  Pakeisti
              </button>
            </form>
          )}
        </div>
      </td>
    </tr>
  );
};
export default RenderAllModulesForUpdate;
/*

 */
