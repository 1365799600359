import { RenderDepartments } from '../Renders/RenderDepartments';
import React, { useEffect, useState } from 'react';
import { useModulesContext } from '../../../context/ModulesContext';
import PostDepartments from '../../../services/post/PostDepartments';
import PostAddModule from '../../../services/post/PostAddModule';
import { MultiSelect } from 'react-multi-select-component';
import '../index.css';
import { useUserContext } from '../../../context/UserContext';
import { useYearsContext } from '../../../context/YearsContext';
import API from '../../../API';
import Searches from './Searches';
import { nanoid } from 'nanoid';
import { useUpdateContext } from '../../../context/UpdateContext';
import GetModules from '../../../services/get/GetModules';
import RenderModulesForSubscriptions from '../Renders/RenderModulesForSubscriptions';
import AuthCheck from '../../../services/auth/AuthCheck';
import PropTypes from 'prop-types';
const CreateModuleSubscription = (props) => {
  AuthCheck();
  const { departments, teachers, handleDepartments, handleAddModule, handleAllModules, handleAllTeacherModules, allModules } = useModulesContext();
  const { errors, handleErrors } = useUserContext();
  const [selectedTeacher, setSelectedTeacher] = useState([]);
  const { years, chosenYear, currentYear } = useYearsContext();
  const { handleUpdateState } = useUpdateContext();
  const [modalOpen, setModalOpen] = useState(false);

  CreateModuleSubscription.propTypes = {
    pageSelect: PropTypes.object
  };

  GetModules();
  useEffect(() => {
    handleErrors('');
  }, []);
  const handleSearch = (input) => {
    PostDepartments(handleDepartments, input.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const allTeachers = [];
    for (let i = 0; i < selectedTeacher.length; i++) {
      allTeachers.push(teachers.find(t => t.fullName === selectedTeacher[i].value));
    }
    console.log(event.target.module_id.value);
    PostAddModule(handleAddModule, handleErrors, allTeachers, event.target.department.value, event.target.module_id.value);
    handleUpdateState(`added module: ${event.target.module_id.value}`);
    let chosenYearId = years.find(o => o.study_year === currentYear.studyYear);
    if (chosenYear !== '' && chosenYear !== null) {
      chosenYearId = years.find(o => o.study_year === chosenYear);
    }
    try {
      API.get(`/users/module-subscriptions?page=${props.pageSelect.current_page}`, {
        study_year: chosenYearId.study_year
      })
        .then(response => {
          handleAllModules(response.data);
        });
      API.get('/user/module-subscriptions', {
        study_year: chosenYearId.study_year
      })
        .then(response => {
          handleAllTeacherModules(response.data);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="container-fluid bg-gray-50 ml-80 w-3/4 min-h-screen">
      <h3 className="text-4xl font-bold mudule-form">Modulių prenumeratos kūrimas</h3>
      <button onClick={() => {setModalOpen(true)}} className="bg-blue-500 hover:bg-blue-700 text-white w-36 font-bold py-2 px-4 rounded mt-5">Sukurti</button>
      <div className={(modalOpen) ? '' : 'hidden'}>
        <div className='w-full h-full fixed top-0 left-0 z-50 bg-black/50 justify-center items-center flex'>
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mudule-form">
              <div className="createModule xl:w-96 mb-6">
                <div>
                  <label className="block text-gray-700 text-2xl font-bold mb-3" htmlFor="users">
                                  Pasirinkite mokytoją
                  </label>
                  <MultiSelect
                    type="text"
                    id="users"
                    multiple="multiple"
                    options={teachers.map(o => { return { label: o.fullName, value: o.fullName }; })}
                    value={selectedTeacher}
                    onChange={setSelectedTeacher}
                    displayValue="users"
                    labelledBy="users"
                    className="form-select form-select-sm
                                              appearance-none
                                              w-full
                                              text-sm
                                              font-normal
                                              text-gray-700
                                              bg-white bg-clip-padding bg-no-repeat
                                              rounded
                                              transition
                                              ease-in-out
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"

                  />
                  {errors.teachers
                    ? <div
                      className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                      role="alert"><span className="font-medium">{errors.teachers}</span>
                    </div>
                    : null}
                </div>
                <div>
                  <label className="block text-gray-700 text-2xl font-bold mb-3 my-3" htmlFor="department">
                                  Grupės pavadinimas
                  </label>
                  <input type="text"
                    multiple
                    name="department"
                    list="grupes"
                    className="form-select form-select-sm
                                              appearance-none
                                              block
                                              w-full
                                              px-2
                                              py-2
                                              text-sm
                                              font-normal
                                              text-gray-700
                                              bg-white bg-clip-padding bg-no-repeat
                                              border border-solid border-gray-300
                                              rounded
                                              transition
                                              ease-in-out
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    onChange={handleSearch}
                    placeholder="Grupė"
                  >
                  </input>
                  {errors.department
                    ? <div
                      className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                      role="alert"><span className="font-medium">{errors.department}</span>
                    </div>
                    : null}
                  <datalist id="grupes">
                    {(departments)
                      ? departments.map((d) => (
                        <RenderDepartments key={nanoid()} id={d.id} department={d.department} />))
                      : null}
                  </datalist>

                </div>
                <div>
                  <label className="block text-gray-700 text-2xl font-bold mb-3 my-3" htmlFor="module_id">
                                  Modulio pavadinimas
                  </label>
                  <select
                    name="module_id"
                    className="form-select form-select-sm
                                              appearance-none
                                              block
                                              w-full
                                              px-2
                                              py-2
                                              text-sm
                                              font-normal
                                              text-gray-700
                                              bg-white bg-clip-padding bg-no-repeat
                                              border border-solid border-gray-300
                                              rounded
                                              transition
                                              ease-in-out
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  >
                    {<option value={0}>Pasirinkti modulį</option> }
                    {allModules ? allModules.map(i => <RenderModulesForSubscriptions key={nanoid()} id={i.id} name={i.name}/>) : null}
                  </select>
                  {errors.module_id
                    ? <div
                      className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                      role="alert"><span className="font-medium">{errors.module_id}</span>
                    </div>
                    : null}
                </div>
                <div>
                  <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white w-36 font-bold py-2 px-4 rounded mt-5 mx-2">Sukurti</button>
                  <button type='button' onClick={() => {setModalOpen(false)}} className="bg-red-500 hover:bg-red-700 text-white w-36 font-bold py-2 px-4 rounded mt-5 mx-2">Atšaukti</button>
                </div>
              </div>
            </form>
        </div>
      </div>
      <div className="modules">
        <Searches pageSelect={props.pageSelect}/>
      </div>
    </div>
  );
};
export default CreateModuleSubscription;
