import API from '../../API';
const PostDepartments = (handleDepartments, search) => {
  return (
    API.post('/departments/search', {
      department: search
    }).then(response => {
      handleDepartments(response.data);
    })
  );
};
export default PostDepartments;
