import { useEffect, useState, React } from 'react';
import { nanoid } from 'nanoid';
import './index.css';

const Pagination = ({ page, setPage, lastPage, step = 15 }) => {
  const [pagination, setPagination] = useState([]);
  const nextPage = () => {
    if (page + 1 <= lastPage) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };
  const pageRange = (page, step, lastPage) => {
    const range = [];

    if (step * 2 + 1 <= step + page) {
      for (let iter = page - step; iter <= page + step; iter++) {
        range.push(iter);
        if (lastPage === iter) {
          break;
        }
      }
    } else {
      for (let iter = 1; iter <= step * 2 + 1; iter++) {
        range.push(iter);
        if (lastPage === iter) {
          break;
        }
      }
    }

    return range;
  };
  useEffect(() => {
    setPagination(pageRange(page, step, lastPage));
  }, [page, lastPage]);
  return (
    <nav>
      { pagination.length
        ? <ul className="inline-flex pag-width -space-x-px pagination flex-wrap">
          <li className="page-item">
            <button className="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 page-link" onClick={() => prevPage()} aria-disabled="true">Atgal</button>
          </li>
          {
            pagination.map(curr => (
              <li key={nanoid()}>
                <button className={`${curr === page
                  ? 'py-2 px-3 leading-tight text-blue-600 bg-blue-50 border border-gray-300 hover:bg-blue-100 hover:text-blue-700'
                  : 'py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 page-link'}`}
                onClick={() => setPage(curr)}>{curr}</button>
              </li>
            ))
          }
          <li className="page-item">
            <button className="py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700" onClick={() => nextPage()}>Kitas</button>
          </li>
        </ul>
        : null}
    </nav>

  );
};

export default Pagination;
