import { useYearsContext } from '../../context/YearsContext';
import API from '../../API';
import { useEffect } from 'react';
import { useModulesContext } from '../../context/ModulesContext';
import { useUpdateContext } from '../../context/UpdateContext';

const GetModulesByPage = (page, searchQuery) => {
  const { handleAllModules, moduleSubscriptions } = useModulesContext();
  const { years, chosenYear, currentYear } = useYearsContext();
  const { updateState } = useUpdateContext();
  useEffect(() => {
    console.log('Loading page ' + page, ' with sq: ' + searchQuery);
    let chosenYearId = years.find(o => o.study_year === currentYear.studyYear);
    if (chosenYear !== '' && chosenYear !== null) {
      chosenYearId = years.find(o => o.study_year === chosenYear);
    }
    try {
      API.get(`/users/module-subscriptions?page=${page}${(searchQuery !== '' ? searchQuery : '')}`, {
        params: {
          study_year: chosenYearId.study_year
        }

      })
        .then(response => {
          handleAllModules(response.data);
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, searchQuery, chosenYear, currentYear, moduleSubscriptions?.meta?.total, updateState]);
};
export default GetModulesByPage;
