import React, { useState } from 'react';
import './../index.css';
import SearchModulesByName from './SearchModulesByName';
import RenderModulesByName from '../Renders/RenderModulesByName';
import { useModulesContext } from '../../../context/ModulesContext';
import GetModulesByPage from '../../../services/get/GetModuleByPage';
import { nanoid } from 'nanoid';
import RenderModuleSubscriptions from '../Renders/RenderModuleSubscriptions';
import Pagination from '../../../misc/Pagination';

const Searches = (props) => {
  const { modulesByName, moduleSubscriptions } = useModulesContext();
  const [page, setPage] = useState(props?.pageSelect?.current_page ?? 1);
  const [searchQuery, setSearchQuery] = useState('');
  GetModulesByPage(page, searchQuery);

  console.log('sq', searchQuery);

  return (
    <div className="container search-container">

      <SearchModulesByName setSearchQuery={setSearchQuery} />
      {(moduleSubscriptions?.data?.length)
        ? <div className="container modules flex-direction-column">
          <RenderModuleSubscriptions page={page} />
          <div>
            {(moduleSubscriptions.meta)
              ? <Pagination page={page} setPage={setPage} lastPage={moduleSubscriptions.meta.last_page}/>
              : null}
          </div>
        </div>
        : <div
          className="p-4 mb-3 text-xl text-red-700 bg-red-100 rounded-lg module-error"
          role="alert"><span className="font-medium">Pasirinktais metais modulių nėra</span>
        </div>
      }
      <div className="padding-5 modules-by-name">
        {(modulesByName)
          ? modulesByName.map(d =>
            <RenderModulesByName key={nanoid()} name={d.name}/>
          )
          : null}
      </div>
    </div>
  );
};
export default Searches;
