import React from 'react';

const RenderErrors = (props) => {
  return (
    <div
      className="p-2.5 mb-1 text-sm text-red-700 w-full bg-red-100 rounded-lg"
      role="alert"><span className="font-medium"><h3 className="text-xl">{props.id + 1}. {props.question}</h3> <h3 className="text-lg">{props.error}</h3></span>
    </div>
  );
};
export default RenderErrors;
