import React, { useContext, useReducer } from 'react';
import {
  addToken,
  addUser,
  getUsers,
  getRoles,
  getUserByRole,
  addErrors,
  addExpire
} from '../actions/UserActions';
import UserReducer from '../reducers/UserReducer';
import {
  tokenStorage,
  userStorage,
  teachersStorage,
  getAllUsersStorage,
  expireStorage,
  getAllRoles,
  getUsersByRoleStorage
} from './constants';
let initialState = {
  token: tokenStorage,
  user: '',
  users: '',
  errors: '',
  expire: expireStorage,
  allUsers: '',
  roles: '',
  userByRole: ''
};

if (userStorage !== null) {
  initialState = {
    token: tokenStorage,
    user: userStorage,
    users: teachersStorage,
    errors: '',
    expire: expireStorage,
    allUsers: getAllUsersStorage,
    roles: getAllRoles,
    userByRole: ''
  };
}

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const handleAddToken = (token) => {
    dispatch(addToken(token));
  };
  const handleAddUser = (token) => {
    dispatch(addUser(token));
  };
  const handleErrors = (data) => {
    dispatch(addErrors(data));
  };
  const handleExpire = (data) => {
    dispatch(addExpire(data));
  };
  const handleGetUsers = (data) => {
    dispatch(getUsers(data));
  };
  const handleGetRoles = (data) => {
    dispatch(getRoles(data));
  };
  const handleGetUserByRole = (data) => {
    dispatch(getUserByRole(data));
  };
  return (
    <UserContext.Provider
      value={{
        ...state,
        handleAddToken,
        handleAddUser,
        handleErrors,
        handleExpire,
        handleGetUsers,
        handleGetRoles,
        handleGetUserByRole
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};

export { UserContext, UserProvider };
