import React, { useState } from 'react';
import { useModulesContext } from '../../../context/ModulesContext';
const RenderCheckboxOptions = ({ id, value, answerType, multipleOptions, answer, index }) => {
  const { checked } = useModulesContext();
  const [userinfo, setUserInfo] = useState({
    answerId: [],
    response: []
  });
  const handleChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { answerId } = userinfo;
    // Case 1 : The user checks the box
    if (checked) {
      setUserInfo({
        answerId: [...answerId, value],
        response: [...answerId, value]
      });
    } else { // Case 2  : The user unchecks the box
      setUserInfo({
        answerId: answerId.filter((e) => e !== value),
        response: answerId.filter((e) => e !== value)
      });
    }
  };
  const isChecked = () => {
    if (checked) {
      for (let i = 0; i < checked[answer - 1].answer_option_id.length; i++) {
        if (checked[answer - 1].answer_option_id[i] === index + 1) {
          return true;
        } else if (checked[answer - 1].answer_option_id[i] === index + 6) {
          return true;
        } else if (checked[answer - 1].answer_option_id[i] === index + 11) {
          return true;
        }
      }
    } else return false;
  };

  let isCheckbox = multipleOptions;

  // console.log('Generating answer', {id, value, answerType, multipleOptions, answer, index});

  let inputClassName = `form-check-input appearance-none h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600
  checked:border-blue-600 focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center
  bg-contain float-left cursor-pointer`;

  if (isCheckbox) inputClassName += ' rounded-sm';
  else inputClassName += ' rounded-full';

  return (
    <div className="form-check">
      <input
        className={inputClassName}
        type={isCheckbox ? 'checkbox' : 'radio'}
        name={answer}
        value={id}
        id={multipleOptions.toString()}
        onChange={handleChange}
        defaultChecked={isChecked()}
      />
      <label
        className="form-check-label ml-6"
        htmlFor="flexCheckDefault"
      >
        {value}
      </label>
    </div>
  );
};
export default RenderCheckboxOptions;
