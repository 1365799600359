import {
  ADD_TOKEN,
  ADD_USER,
  GET_USERS,
  GET_ROLES,
  GET_USER_BY_ROLE,
  ADD_ERRORS,
  ADD_EXPIRE
} from './types';
export const addToken = (data) => {
  localStorage.setItem('token', data);
  return {
    type: ADD_TOKEN,
    payload: data
  };
};
export const addUser = (data) => {
  localStorage.setItem('user', JSON.stringify(data));
  return {
    type: ADD_USER,
    payload: data
  };
};
export const addErrors = (data) => {
  return {
    type: ADD_ERRORS,
    payload: data
  };
};
export const addExpire = (data) => {
  localStorage.setItem('expire', data);
  return {
    type: ADD_EXPIRE,
    payload: data
  };
};
export const getUsers = (data) => {
  localStorage.setItem('allusers', JSON.stringify(data));
  return {
    type: GET_USERS,
    payload: data
  };
};
export const getRoles = (data) => {
  localStorage.setItem('allroles', JSON.stringify(data));
  return {
    type: GET_ROLES,
    payload: data
  };
};
export const getUserByRole = (data) => {
  localStorage.setItem('userByRole', JSON.stringify(data));
  return {
    type: GET_USER_BY_ROLE,
    payload: data
  };
};
