import API from '../../API';
const PostModules = (handleModules, search) => {
  return (
    API.post('/modules/search', {
      module_name: search
    }).then(response => {
      handleModules(response.data);
    })
  );
};
export default PostModules;
