import React from 'react';
import RenderModules from '../Renders/RenderModules';
import { useModulesContext } from '../../../context/ModulesContext';
import { nanoid } from 'nanoid';

const RenderModuleSubscriptions = (props) => {
  const {page} = props;
  const { moduleSubscriptions } = useModulesContext();

  console.log(moduleSubscriptions);

  return (
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs uppercase bg-blue-500 text-white">
            <tr>
              <th scope="col" className="px-6 py-3">Modulio pavadinimas</th>
              <th scope="col" className="px-9 py-3">Studijų metai</th>
              <th scope="col" className="px-9 py-3">Departamentas</th>
              <th scope="col" className="px-9 py-3">Veiksmai</th>
            </tr>
          </thead>
          <tbody>
            {(moduleSubscriptions.data)
              ? moduleSubscriptions.data.map(d =>
                <RenderModules key={nanoid()} id={d.id} department={d.department} module={d.module} studyYear={d.studyYear} teachers={d.teachers}/>
              )
              : null}
          </tbody>
        </table>
        <p>Rodomas puslapis: {page}</p>
      </div>
  );
};
export default RenderModuleSubscriptions;
