import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import RenderRoles from './RenderRoles';
import { useUserContext } from '../../context/UserContext';
import RenderRolesOptions from './RenderRolesOptions';
import PostAddRoles from '../../services/post/PostAddRoles';
import './useradministration.css';
import { useUpdateContext } from '../../context/UpdateContext';

const RenderAllUsers = (props) => {
  const { roles, handleGetUserByRole } = useUserContext();
  const { handleUpdateState } = useUpdateContext();

  const handleAddRole = (event) => {
    event.preventDefault();
    PostAddRoles(handleUpdateState, event.target.role.value, props.id, handleGetUserByRole);
  };

  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const toggleAdvancedOpen = (event) => {
    event.preventDefault();

    setIsAdvancedOpen((currentState) => !currentState);
  };
  return (
    <tr className="bg-white border-b">
      <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap flex flex-wrap w-72 h-20">
        {props.fullName}
      </td>
      <td className="px-3 py-4">{props.email}</td>
      <td className="px-3 py-4">{props.department}</td>
      <td className="px-6 py-4 text-left lyginimas">
        {props.roles
          ? props.roles.map((r) => (
            <RenderRoles
              key={nanoid()}
              id={r.id}
              userRoleId={r.userRoleId}
              name={r.name}
              userid={props.id}
            />
          ))
          : null}
      </td>
      <td className="px-3 py-4">
        <button onClick={toggleAdvancedOpen}>PRIDĖTI ROLĘ</button>
        {isAdvancedOpen && (
          <form onSubmit={handleAddRole}>
            <select name="role" >
              {roles
                ? roles.map((b) => <RenderRolesOptions key={nanoid()} id={b.id} name={b.name} />)
                : null}
            </select>
            <button type="submit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-6 addroleicon"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="3"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
              </svg>
            </button>
          </form>
        )}
      </td>
    </tr>
  );
};
export default RenderAllUsers;
