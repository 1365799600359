import React, { useEffect, useState } from "react";
import "../index.css";
import Navbar from "../../Navbar/Navbar";
import Account from "../../Account/Account";
import AuthCheck from "../../../services/auth/AuthCheck";
import { nanoid } from "nanoid";
import { useYearsContext } from "../../../context/YearsContext";
import { useModulesContext } from "../../../context/ModulesContext";
import API from "../../../API";
import "chart.js/auto";
import { Pie, Line } from "react-chartjs-2";
const ModuleGraphs = () => {
  AuthCheck();

  // const {teacher_module_subscriptions, handleAllTeacherModules} = useModulesContext()
  const { years, chosenYear, currentYear } = useYearsContext();
  const { teachers } = useModulesContext();

  const chosenYearId = years.find(
    (o) => o.study_year === currentYear.studyYear
  );
  useEffect(() => {
    const result = years.find((o) => o.study_year === chosenYear);
  }, [chosenYear, chosenYearId, years]);

  const [errors, setErrors] = useState({
    type: "",
    teacher: "",
    year: "",
    message: "",
  });

  const [selectedStatistic, setSelectedStatistic] = useState("");
  const [selectedStatisticType, setSelectedStatisticType] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");

  const [loading, setLoading] = useState(false);
  const [graphData1, setGraphData1] = useState(null);
  const [graphData2, setGraphData2] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    let user_id = event?.target?.user_id?.value;
    let statistic_type = event.target.statistic_type.value;
    let year = event.target.year.value;

    if (user_id === undefined) user_id = "fill_me_up";

    console.log(user_id, statistic_type);

    let error_temp = errors;

    if (statistic_type !== "") {
      error_temp = { ...error_temp, type: "" };
    } else {
      console.log("Set error");
      error_temp = {
        ...error_temp,
        type: "Būtina pasirinkti statistikos tipą",
      };
    }

    if (year !== "") {
      error_temp = { ...error_temp, year: "" };
    } else {
      error_temp = { ...error_temp, year: "Būtina pasirinkti mokslo metus" };
    }

    if (user_id !== "") {
      error_temp = { ...error_temp, teacher: "" };
    } else {
      error_temp = { ...error_temp, teacher: "Būtina pasirinkti mokytoją" };
    }

    setErrors(error_temp);

    if (statistic_type !== "" && user_id !== "" && year !== "") {
      console.log("Worku");
      setLoading(true);
      setGraphData1(null);
      setGraphData2(null);

      if (statistic_type === "Mokytojo naudojami ištekliai") {
        API.get(`/survey/teacher/${user_id}/${year}/statistics1`).then(
          (response) => {
            console.log(response.data.data);
            let all_labels = [
              "Moodle",
              "MS Teams",
              "Google diskas",
              "Eduka",
              "Gitlab kodo saugykla",
              "Ftp paslauga",
              "Siunčia į el. paštą",
              "Dalijasi facebook grupėje",
              "MS OneDrive",
              "Kita",
              "Niekur netalpina",
            ];
            let labels = [];
            let data = [];

            for (let i = 0; i < response.data.data.length; i++) {
              const element = response.data.data[i];
              if (element > 0) {
                labels.push(all_labels[i]);
                data.push(element);
              }
            }

            setGraphData1({
              labels: labels,
              datasets: [
                {
                  label: "balsai",
                  data: data,
                  borderWidth: 1,
                },
              ],
            });
            setLoading(false);
          }
        );
      } else if (statistic_type === "KITM rekomenduoja") {
        API.get(`/survey/teacher/${year}/statistics2`).then((response) => {
          console.log(response.data.data);

          let all_labels = ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1"];
          let labels = [];
          let data = [];

          for (let i = 0; i < response.data.data.length; i++) {
            const element = response.data.data[i];
            if (element > 0) {
              labels.push(all_labels[i]);
              data.push(element);
            }
          }

          setGraphData1({
            labels: labels,
            datasets: [
              {
                label: "balsai",
                data: data,
                borderWidth: 1,
              },
            ],
          });
          setLoading(false);
        });
      } else if (statistic_type === "Mokytojo darbo kokybės analizė") {
        if (selectedStatisticType === 'Suminė ataskaita') {
          API.get(`/survey/teacher/${year}/statistics3`).then((response) => {
            console.log(response.data.data);
  
            let all_labels = ["Mokomosios medžiagos naudingumas", "Mokomosios medžiagos pateikimas", "Pažangūs mokymosi metodai", "Vertinimo kriterijų aiškumas", "Materialiniai ištekliai", "Mikroklimatas"];
            let labels = [];
            let data = response.data.data;
  
            for (let i = 0; i < response.data.data.length; i++) {
              const element = response.data.data[i];
              if (element > 0) {
                labels.push(all_labels[i]);
                // data.push(element);
              }
            }
  
            setGraphData2({
              labels: ["1", "2", "3", "4", "5"],
              datasets: [
                {
                  label: all_labels[0],
                  data: data[0].reverse(),
                  borderWidth: 1,
                },
                {
                  label: all_labels[1],
                  data: data[1].reverse(),
                  borderWidth: 1,
                },
                {
                  label: all_labels[2],
                  data: data[2].reverse(),
                  borderWidth: 1,
                },
                {
                  label: all_labels[3],
                  data: data[3].reverse(),
                  borderWidth: 1,
                },
                {
                  label: all_labels[4],
                  data: data[4].reverse(),
                  borderWidth: 1,
                },
                {
                  label: all_labels[5],
                  data: data[5].reverse(),
                  borderWidth: 1,
                },
              ],
            });
            setLoading(false);
          });
        }
        else if (selectedStatisticType === 'Mokytojo ataskaita') {
          API.get(`/survey/teacher/${user_id}/${year}/statistics3`).then((response) => {
            console.log(response.data.data);
  
            let all_labels = ["Mokomosios medžiagos naudingumas", "Mokomosios medžiagos pateikimas", "Pažangūs mokymosi metodai", "Vertinimo kriterijų aiškumas", "Materialiniai ištekliai", "Mikroklimatas"];
            let labels = [];
            let data = response.data.data;
  
            for (let i = 0; i < response.data.data.length; i++) {
              const element = response.data.data[i];
              if (element > 0) {
                labels.push(all_labels[i]);
                // data.push(element);
              }
            }
  
            setGraphData2({
              labels: ["1", "2", "3", "4", "5"],
              datasets: [
                {
                  label: all_labels[0],
                  data: data[0].reverse(),
                  borderWidth: 1,
                },
                {
                  label: all_labels[1],
                  data: data[1].reverse(),
                  borderWidth: 1,
                },
                {
                  label: all_labels[2],
                  data: data[2].reverse(),
                  borderWidth: 1,
                },
                {
                  label: all_labels[3],
                  data: data[3].reverse(),
                  borderWidth: 1,
                },
                {
                  label: all_labels[4],
                  data: data[4].reverse(),
                  borderWidth: 1,
                },
                {
                  label: all_labels[5],
                  data: data[5].reverse(),
                  borderWidth: 1,
                },
              ],
            });
            setLoading(false);
          });
        }
      }
    }
  };

  return (
    <>
      <Navbar />
      <Account />

      <div className="container-fluid bg-gray-50 ml-80 w-3/4 min-h-screen">
        <h3 className="text-4xl font-bold mudule-form">
          Vizualinė duomenų analizė
        </h3>
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mudule-form"
        >
          <div className="createModule xl:w-96 mb-6">
            <>
              <div>
                <label
                  className="block text-gray-700 text-2xl font-bold my-3"
                  htmlFor="statistic_type"
                >
                  Statistikos tipas
                </label>
                <select
                  name="statistic_type"
                  id="statistic_type"
                  className="form-select form-select-sm
                                              appearance-none
                                              block
                                              w-full
                                              px-2
                                              py-2
                                              text-sm
                                              font-normal
                                              text-gray-700
                                              bg-white bg-clip-padding bg-no-repeat
                                              border border-solid border-gray-300
                                              rounded
                                              transition
                                              ease-in-out
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  onChange={(e) => {
                    setSelectedStatistic(e.target.value);
                  }}
                  value={selectedStatistic ?? ""}
                >
                  <option value="">Pasirinkite tipą</option>
                  <option>Mokytojo naudojami ištekliai</option>
                  <option>KITM rekomenduoja</option>
                  <option>Mokytojo darbo kokybės analizė</option>
                </select>
              </div>
              {errors.type ? (
                <div
                  className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                  role="alert"
                >
                  <span className="font-medium">{errors.type}</span>
                </div>
              ) : null}

              <div>
                <label
                  className="block text-gray-700 text-2xl font-bold mb-3 my-3"
                  htmlFor="year"
                >
                  Mokslo metai
                </label>

                <select
                  name="year"
                  id="year"
                  className="form-select form-select-sm
                                              appearance-none
                                              block
                                              w-full
                                              px-2
                                              py-2
                                              text-sm
                                              font-normal
                                              text-gray-700
                                              bg-white bg-clip-padding bg-no-repeat
                                              border border-solid border-gray-300
                                              rounded
                                              transition
                                              ease-in-out
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  onChange={(e) => {
                    setSelectedYear(e.target.value);
                  }}
                  value={selectedYear ?? ""}
                >
                  {years
                    ? years.map((t) => (
                        <option key={nanoid()} value={t.id}>
                          {t.study_year}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              {errors.year ? (
                <div
                  className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                  role="alert"
                >
                  <span className="font-medium">{errors.year}</span>
                </div>
              ) : null}

              {selectedStatistic === "Mokytojo darbo kokybės analizė" ? (
                <>
                  <div>
                    <label
                      className="block text-gray-700 text-2xl font-bold my-3"
                      htmlFor="statistic_type_subtype"
                    >
                      Statistikos tipas
                    </label>
                    <select
                      name="statistic_type_subtype"
                      id="statistic_type_subtype"
                      className="form-select form-select-sm
                                              appearance-none
                                              block
                                              w-full
                                              px-2
                                              py-2
                                              text-sm
                                              font-normal
                                              text-gray-700
                                              bg-white bg-clip-padding bg-no-repeat
                                              border border-solid border-gray-300
                                              rounded
                                              transition
                                              ease-in-out
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      onChange={(e) => {
                        setSelectedStatisticType(e.target.value);
                      }}
                      value={selectedStatisticType ?? ""}
                    >
                      <option value="">Pasirinkite tipą</option>
                      <option>Suminė ataskaita</option>
                      <option>Mokytojo ataskaita</option>
                    </select>
                  </div>
                </>
              ) : null}

              {selectedStatistic === "Mokytojo naudojami ištekliai" ||
              (selectedStatistic === "Mokytojo darbo kokybės analizė" && selectedStatisticType !== '' && selectedStatisticType !== 'Suminė ataskaita') ? (
                <div>
                  <label
                    className="block text-gray-700 text-2xl font-bold mb-3 my-3"
                    htmlFor="user_id"
                  >
                    Mokytojo vardas
                  </label>

                  <select
                    name="user_id"
                    id="user_id"
                    className="form-select form-select-sm
                                              appearance-none
                                              block
                                              w-full
                                              px-2
                                              py-2
                                              text-sm
                                              font-normal
                                              text-gray-700
                                              bg-white bg-clip-padding bg-no-repeat
                                              border border-solid border-gray-300
                                              rounded
                                              transition
                                              ease-in-out
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    onChange={(e) => {
                      setSelectedTeacher(e.target.value);
                    }}
                    value={selectedTeacher ?? ""}
                  >
                    <option key={nanoid()} value="">
                      Pasirinkite mokytoją
                    </option>
                    {teachers
                      ? teachers.map((t) => (
                          <option key={nanoid()} value={t.id}>
                            {t.fullName}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              ) : null}
              {errors.teacher ? (
                <div
                  className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                  role="alert"
                >
                  <span className="font-medium">{errors.teacher}</span>
                </div>
              ) : null}
            </>
            <div className="">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white w-36 font-bold py-2 px-4 rounded mt-5"
              >
                Generuoti
              </button>
            </div>
          </div>
        </form>

        {loading ? (
          <div className="shadow-md px-8 pt-6 pb-8 mb-4 w-1/2 text-red-700 bg-red-100 rounded-lg">
            <h3 className="text-2xl font-bold flex justify-center">
              Renkami duomenys... Prašome palaukti...
            </h3>
          </div>
        ) : null}

        {graphData1 ? (
          <div className="w-1/3">
            <Pie data={graphData1} />
          </div>
        ) : null}
        {graphData2 ? (
          <div className="w-2/3">
            <Line data={graphData2} options={{responsive: true}} />
          </div>
        ) : null}
      </div>
    </>
  );
};
export default ModuleGraphs;
