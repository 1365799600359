import React from 'react';
import PropTypes from 'prop-types';

const RenderSurveyAnswersPaginate = (props) => {
  RenderSurveyAnswersPaginate.propTypes = {
    id: PropTypes.object,
    question: PropTypes.object,
    answer: PropTypes.object,
    answerOptions: PropTypes.object
  };

  console.log('debug2', props);

  return (
    <>
      <div className="text-1.5xl font-bold mt-2 mb-2">{props.id}. {props.question}</div>
      {props.answer != null
        ? <div style={{ color: 'green' }}>{props.answer}</div>
        : null}
      {props.answerOptions != null
        ? <>
          <div style={{ color: 'green' }}>{props.answerOptions[0]}</div>
          <div style={{ color: 'green' }}>{props.answerOptions[1]}</div>
          <div style={{ color: 'green' }}>{props.answerOptions[2]}</div>
          <div style={{ color: 'green' }}>{props.answerOptions[3]}</div>
          <div style={{ color: 'green' }}>{props.answerOptions[4]}</div>
          <div style={{ color: 'green' }}>{props.answerOptions[5]}</div>
          <div style={{ color: 'green' }}>{props.answerOptions[6]}</div>
          <div style={{ color: 'green' }}>{props.answerOptions[7]}</div>
          <div style={{ color: 'green' }}>{props.answerOptions[8]}</div>
          <div style={{ color: 'green' }}>{props.answerOptions[9]}</div>
          <div style={{ color: 'green' }}>{props.answerOptions[10]}</div>
        </>

        : null}
    </>
  );
};
export default RenderSurveyAnswersPaginate;
