import API from '../../API';
import { useUserContext } from '../../context/UserContext';
import RenderRolesOptions from './RenderRolesOptions';
import PostDeleteRole from '../../services/post/PostDeleteRole';
import './useradministration.css';
import { useUpdateContext } from '../../context/UpdateContext';
import React from 'react';

const RenderRoles = (props) => {
  const { roles, handleGetUserByRole } = useUserContext();
  const { handleUpdateState } = useUpdateContext();
  const handleSubmit = (event) => {
    event.preventDefault();
    API.post(`user/update-role/${props.userRoleId}`, {
      role_id: event.target.roles.value
    }).then(() => {
      handleUpdateState(`updated role:${event.target.roles.value} for user: ${props.userRoleId}`);
      handleGetUserByRole('');
    });
  };
  const deleteRole = () => {
    handleUpdateState(`deleted role:${props.userRoleId}`);
    PostDeleteRole(props.userRoleId);
    handleGetUserByRole('');
  };
  return (
    <>
      <div className="flex-direction-row">
        <div className="makeinline">
          <div>{props.name}</div>
          <div>
            <a onClick={deleteRole} href="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-6 ml-1 deleteiconcolor"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </a>
          </div>
          <form onSubmit={handleSubmit}>
            <select name="roles" type="text">
              {roles
                ? roles.map((a) => <RenderRolesOptions key={a.id} id={a.id} name={a.name} />)
                : null}
            </select>
            <button type="submit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-6 updateiconcolor"
                fill="none"
                viewBox="0 0 24 20"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default RenderRoles;
