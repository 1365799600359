import { useEffect } from 'react';
import API from '../../API';
import { useModulesContext } from '../../context/ModulesContext';
const GetModules = (updateState) => {
  const { handleModules } = useModulesContext();

  useEffect(() => {
    API.get('/modules')
      .then(resp => {
        handleModules(resp.data);
      });
  }, [updateState]);
};
export default GetModules;
