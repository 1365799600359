import '../index.css';
import React from 'react';
import CreateModuleSubscription from './CreateModuleSubscription';
import { useModulesContext } from '../../../context/ModulesContext';
import Navbar from '../../Navbar/Navbar';
import Account from '../../Account/Account';
import AuthCheck from '../../../services/auth/AuthCheck';

const ModulesSubscription = () => {
  const { moduleSubscriptions } = useModulesContext();
  AuthCheck();
  return (
    <>
      <Navbar />
      <Account />
      <div>
        <CreateModuleSubscription pageSelect={moduleSubscriptions.meta}/>
      </div>
    </>
  );
};

export default ModulesSubscription;
