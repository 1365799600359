import API from '../../../API';
import { useUpdateContext } from '../../../context/UpdateContext';
import React from 'react';
import { useModulesContext } from '../../../context/ModulesContext';
import RenderNames from './RenderNames';
const RenderTeacherModulesForForm = (props) => {
  const { handleUpdateState } = useUpdateContext();
  const { handleGetSurvey } = useModulesContext();
  const handleShowForm = () => {
    try {
      API.get(`/survey/module-subscription/${props.id}`)
        .then(response => {
          handleUpdateState(props.id);
          handleGetSurvey(response.data);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {props.surveyActive === 1
        ? <a href="#"
          className="p-3 max-w-sm w-60 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 text-center">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Mokytojų:
            {props.teachers.map(t => <RenderNames key={t.id} full_name={t.full_name}/>)}</h5>
          <p className="font-normal text-gray-700">Modulis: {props.module}</p>
          {Object.keys(props.answers).length > 0
          ? <button disabled className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-3 border border-blue-700 rounded buttonTeachers mt-6">Apklausa jau užpildyta</button>
          : <button onClick={handleShowForm} className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-3 border border-blue-700 rounded buttonTeachers mt-6">Pildyti Apklausą</button>
          }
        </a>
        : null}
    </>
  );
};
export default RenderTeacherModulesForForm;
