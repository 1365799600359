import React from 'react';

const Modal = ({ setShowModal, confirm, text, btnText }) => {
  return (
    <div className="container-fluid">
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed
                        inset-0 z-50 outline-none focus:outline-none">
        <div className="w-auto my-6 mx-auto max-w-3xl border-solid border-2 border-indigo-600 rounded bg-indigo-100 modal-css">
          <div>
            <div className="p-5 border-b border-solid rounded-t border-indigo-300">
              <h3 className="text-3xl font-semibold">
                {text}
              </h3>
              <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl
                                        leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal(false)}>
              </button>
            </div>
            <div className="flex items-center justify-center p-6">
              <button
                className="bg-white text-red-500 active:bg-white font-bold uppercase text-sm px-6 py-3 rounded
                                            shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                                Atšaukti
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded
                                            shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={confirm}
              >
                {btnText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
