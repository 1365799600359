import React, { useEffect, useState } from 'react';
import '../index.css';
import Navbar from '../../Navbar/Navbar';
import Account from '../../Account/Account';
import AuthCheck from '../../../services/auth/AuthCheck';
import { nanoid } from 'nanoid';
import { useYearsContext } from '../../../context/YearsContext';
import API from '../../../API';
const ActivatedModuleSubscriptions = () => {
  AuthCheck();
  const [subscriptions, setSubscriptions] = useState([]);

  // const {teacher_module_subscriptions, handleAllTeacherModules} = useModulesContext()
  const { years, chosenYear, currentYear } = useYearsContext();

  const chosenYearId = years.find(o => o.study_year === currentYear.studyYear);
  useEffect(() => {
    const result = years.find(o => o.study_year === chosenYear);

    try {
      API.get(`/survey/teacher/${result.id}/count`)
        .then(subscriptionsResponse => {
          // For debugging only
          // console.log('sub_response', subscriptionsResponse.data);
          setSubscriptions(subscriptionsResponse.data);
        });
    } catch (error) {
      console.error(error);
    }
  }, [chosenYear, chosenYearId, years]);

  // console.log(teacher_module_subscriptions);

  return (
    <>
      <Navbar />
      <Account />

      <div className="container-fluid bg-gray-50 ml-80 w-3/4 min-h-screen">
        <h3 className="text-4xl font-bold mudule-form">Apklausų pildymas</h3>
        <div className='mt-5 overflow-x-hidden shadow-md sm:rounded-lg'>
          <table className='w-full text-sm text-left text-gray-500 mr-7'>
            <thead className='text-xs uppercase bg-blue-500 text-white'>
              {subscriptions?.data ?
              <tr>
                <td className='px-3 py-3'>Modulis (departamentas)</td>
                <td className='px-3 py-3'>Užpildžiųsių mk. skaičius</td>
                <td className='px-3 py-3'>Mokytojas</td>
              </tr> : null }
            </thead>
            <tbody>
              {subscriptions?.data
                ? subscriptions.data.map(i => <tr key={nanoid()} id={i.id} className="bg-white border-b">
                  <td className="px-3 py-4 font-medium text-gray-900 flex flex-wrap w-72">{i?.subscription?.module?.name} ({i?.subscription?.department})</td>
                  <td>{i.answers}</td>
                  <td>{i?.teachers ? i.teachers.map(t => <span className='px-2' key={t.id}>{t.full_name}</span>) : null}</td>
                </tr>)
                : <div className='m-1 p-1'>
                    <img className='mx-auto' src="https://i.gifer.com/XOsX.gif" alt="Krovimo nuotrauka" />
                    <p className='text-3xl'>Duomenys kraunami...</p>
                    <p>Tai gali užtrukti iki 2 minučių!</p>
                    <br />
                    <p>Tuo tarpu galite pasivaikščioti arba pasidaryti kavos ☕</p>
                  </div>}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default ActivatedModuleSubscriptions;
