import PostActivateSurvey from '../../../services/post/PostActivateSurvey';
import API from '../../../API';
import { useModulesContext } from '../../../context/ModulesContext';
import { useYearsContext } from '../../../context/YearsContext';
import { useUpdateContext } from '../../../context/UpdateContext';
import React, { useState } from 'react';
import Modal from '../../../misc/Modal';
import { nanoid } from 'nanoid';
const RenderTeacherModulesInactive = (props) => {
  const { handleAllTeacherModules } = useModulesContext();
  const { handleUpdateState } = useUpdateContext();
  const { years, chosenYear, currentYear } = useYearsContext();
  const [showModal, setShowModal] = useState(false);
  const [modalOptions, setModalOptions] = useState({});
  const handleModalOptions = () => {
    setShowModal(true);
    setModalOptions({
      text: `Ar tikrai norite aktyvuoti "${props.module}" modulį`,
      btnText: 'Aktyvuoti'
    });
  };
  const handleSurveyActivation = () => {
    PostActivateSurvey(props.id);
    setShowModal(false);
    let chosenYearId = years.find(o => o.study_year === currentYear.studyYear);
    if (chosenYear !== '' && chosenYear !== null) {
      chosenYearId = years.find(o => o.study_year === chosenYear);
    }
    try {
      API.get('/user/module-subscriptions', {
        study_year: chosenYearId.study_year
      })
        .then(response => {
          handleUpdateState(`updated to active:${props.id}`);
          handleAllTeacherModules(response.data);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <tr key={nanoid()} id={props.id} className="bg-white border-b">
        <td className='px-3 py-4 font-medium text-gray-900 flex flex-wrap w-72'>{props.department}</td>
        <td>{props.module}</td>
        <td className={props.surveyActive ? "text-green-500" : "text-red-500"}>{props.surveyActive ? 'Aktyvi' : 'Neaktyvi'}</td>
        <td>{(props.surveyActive === 0)
        ? <button onClick={handleModalOptions} className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-3 border border-blue-700 rounded buttonTeachers">Aktyvuoti apklausą</button>
        : null
        }</td>
      </tr>
      {(showModal && props.surveyActive !== 1)
        ? (
          <Modal setShowModal={setShowModal} confirm={handleSurveyActivation} text={modalOptions.text} btnText={modalOptions.btnText}/>
        )
        : null}
    </>
  );
};
export default RenderTeacherModulesInactive;
