import { useUserContext } from '../../context/UserContext';
import './account.css';
import { Fragment, React } from 'react';
import { Menu, Transition } from '@headlessui/react';
import PostLogout from '../../services/post/PostLogout';
import Years from './Years';
function classNames (...classes) {
  return classes.filter(Boolean).join(' ');
}
const Account = () => {
  const { user } = useUserContext();
  const logout = () => {
    PostLogout();
  };
  return (
    <>
      {(user.roles.find(t => t.name === 'admin') || user.roles.find(t => t.name === 'darbuotojas') || user.roles.find(t => t.name === 'mokytojas'))
        ? <Years/>
        : null}
      {user
        ? <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="text-blue-500 h-14 w-11"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clipRule="evenodd"
              />
            </svg>

          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      <div>{user.fullName}</div>
                      <div>{user.roles[0].name}</div>
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                      onClick={logout}
                    >
                                        Atsijungti
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        : null}
    </>
  );
};
export default Account;
