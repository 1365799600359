import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login/Login';
import PostCallback from './services/post/PostCallback';
import Main from './components/Main/Main';
import SchoolYear from './components/SchoolYear/SchoolYear';
import ModulesSubscription from './components/Modules/ModulesCreation/ModulesSubscription';
import TeacherModules from './components/Modules/TeacherModules/TeacherModules';
import { UserProvider } from './context/UserContext';
import { ModulesProvider } from './context/ModulesContext';
import { YearsProvider } from './context/YearsContext';
import ModuleSurvey from './components/Modules/ModuleSurvey/ModuleSurvey';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import UserAdministration from './components/UserAdministration/UserAdministration';
import { UpdateProvider } from './context/UpdateContext';
import ModuleSelect from './components/Modules/ModuleSurvey/ModuleSelect';
import DepartmentAdmin from './components/Departments/DepartmentAdmin';
import WorkerMenu from './components/WorkerMenu/WorkerMenu';
import SurveyAnswerPages from './components/WorkerMenu/SurveyAnswerPages';
import SurveyImport from './components/SurveyImport/SurveyImport';
import ModuleCreate from './components/Modules/ModulesCreation/ModuleCreate';
import ActivatedModuleSubscriptionsDetailed from './components/Modules/ActivatedModuleSubscriptionsDetailed/ActivatedModuleSubscriptionsDetailed';
import ActivatedModuleSubscriptionsDetailed2 from './components/Modules/ActivatedModuleSubscriptionsDetailed2/ActivatedModuleSubscriptionsDetailed2';
import ModuleGraphs from './components/Modules/ModulesGraphs/ModuleGraphs';
const App = () => {
  const notify = () => toast;
  return (
    <div>
      <UserProvider>
        <ModulesProvider>
          <YearsProvider>
            <UpdateProvider>
              <Router>
                <div className="absolute capitalize">
                  <button onClick={notify}></button>
                  <ToastContainer />
                </div>
                <Routes>
                  <Route exact path="/" element={<Login/>}/>
                  <Route exact path="/callback" element={<PostCallback/>}/>
                  <Route exact path="/autentifikuota" element={<Main/>}/>
                  <Route exact path="/autentifikuota/mokslo-metai" element={<SchoolYear/>}/>
                  <Route exact path="/autentifikuota/sukurti-modulio-prenumerata" element={<ModulesSubscription/>}/>
                  <Route exact path="/autentifikuota/sukurti-moduli" element={<ModuleCreate/>}/>
                  <Route exact path="/autentifikuota/mokytojo-moduliai" element={<TeacherModules/>}/>
                  <Route exact path="/autentifikuota/modulio-apklausa" element={<ModuleSurvey/>}/>
                  <Route exact path="/autentifikuota/modulio-pasirinkimas" element={<ModuleSelect/>}/>
                  <Route exact path="/autentifikuota/vartotoju-administravimas" element={<UserAdministration/>}/>
                  <Route exact path="/autentifikuota/departamentu-administravimas" element={<DepartmentAdmin/>}/>
                  <Route exact path="/autentifikuota/darbuotojo-menu" element={<WorkerMenu/>}/>
                  <Route exact path="/autentifikuota/nesumine-apklausu-ataskaita" element={<SurveyAnswerPages/>}/>
                  <Route exact path="/autentifikuota/apklausu-importavimas" element={<SurveyImport/>}/>
                  <Route exact path="/autentifikuota/apklausu-pildymas" element={<ActivatedModuleSubscriptionsDetailed/>}/>
                  <Route exact path="/autentifikuota/apklausu-pildymas-2" element={<ActivatedModuleSubscriptionsDetailed2/>}/>
                  <Route exact path="/autentifikuota/vizualine-duomenu-analize" element={<ModuleGraphs/>}/>
                </Routes>
              </Router>
            </UpdateProvider>
          </YearsProvider>
        </ModulesProvider>
      </UserProvider>
    </div>
  );
};
export default App;
