import Navbar from "../Navbar/Navbar";
import Account from "../Account/Account";
import React, { useEffect, useState } from "react";
import AuthCheck from "../../services/auth/AuthCheck";
import API from "../../API";
import { useUpdateContext } from "../../context/UpdateContext";
import { useModulesContext } from "../../context/ModulesContext";
import { useYearsContext } from "../../context/YearsContext";
import RenderModules from "./RenderModules";
import RenderTeachers from "./RenderTeachers";
import RenderAnswers from "./RenderAnswers";
import { nanoid } from "nanoid";
import PostModules from "../../services/post/PostModules";

const WorkerMenu = () => {
  AuthCheck();
  const { updateState, handleUpdateState } = useUpdateContext();
  const {
    handleGetTeacherSurveyAnswers,
    teachers,
    handleGetModules,
    modulesByName,
    teacherSurveyAnswers,
  } = useModulesContext();
  const { years, chosenYear, currentYear } = useYearsContext();
  const [modules, setModules] = useState([]);
  const [direction, setDirection] = useState(true);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [moduleTeachers, setModuleTeachers] = useState([]);
  const [teacherModules, setTeacherModules] = useState([]);
  const [errors] = useState({});

  function switchDirection(e) {
    e.preventDefault();
    setDirection(!direction);
  }
  useEffect(() => {
    let chosenYearId = years.find(
      (o) => o.study_year === currentYear.studyYear
    );
    if (chosenYear !== "" && chosenYear !== null) {
      chosenYearId = years.find((o) => o.study_year === chosenYear);
    }
    try {
      API.get("/users/module-subscriptions?page=1", {
        params: {
          study_year: chosenYearId.study_year,
          per_page: 999,
        },
      }).then((response) => {
        const uniqueModules = response.data.data.filter(
          (obj, index, self) =>
            index === self.findIndex((o) => o.module === obj.module)
        );
        setModules(uniqueModules);
        console.log(uniqueModules);
      });
    } catch (err) {
      console.log(err);
    }
  }, [chosenYear, updateState]);
  const [searchedUser, setUser] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.target.user_id.value !== "") {
      setUser(
        teachers.find((t) => t.fullName === event.target.user_id.value).id
      );
      Object.assign(errors, { teacher: "" });
    } else {
      Object.assign(errors, { teacher: "Būtina pasirinkti mokytoją" });
    }
    if (event.target.module_name.value !== "") {
      Object.assign(errors, { module: "" });
    } else {
      Object.assign(errors, { module: "Būtina įrašyti modulio pavadinimą" });
    }
    PostModules(handleGetModules, event.target.module_name.value);
    if (
      event.target.module_name.value !== "" &&
      event.target.user_id.value !== ""
    ) {
      handleUpdateState(
        `search happened on: ${event.target.user_id.value} ${event.target.module_name.value}`
      );
    }
  };
  useEffect(() => {
    if (selectedModule == null) return;
    if (!direction) return;

    let chosenYearId = years.find(
      (o) => o.study_year === currentYear.studyYear
    );
    if (chosenYear !== "" && chosenYear !== null) {
      chosenYearId = years.find((o) => o.study_year === chosenYear);
    }
    // On selected module
    API.get("/users/module-subscriptions", {
      params: {
        study_year: chosenYearId.study_year,
        per_page: 30,
        module: selectedModule,
      },
    }).then((response) => {
      const uniqueTeachers = Array.from(
        new Set(
          [].concat(
            ...response.data.data.map((obj) =>
              obj.teachers.map((innerObj) => innerObj.full_name)
            )
          )
        )
      );
      setModuleTeachers(uniqueTeachers);
      console.log(uniqueTeachers);
    });
  }, [selectedModule, direction]);

  useEffect(() => {
    if (selectedTeacher == null) return;
    if (direction) return;

    let chosenYearId = years.find(
      (o) => o.study_year === currentYear.studyYear
    );
    if (chosenYear !== "" && chosenYear !== null) {
      chosenYearId = years.find((o) => o.study_year === chosenYear);
    }
    // On selected module
    API.get("/users/module-subscriptions", {
      params: {
        study_year: chosenYearId.study_year,
        per_page: 30,
        teacher: selectedTeacher,
      },
    }).then((response) => {
      const uniqueModules = Array.from(
        new Set(
          [].concat(
            ...response.data.data.map((obj) =>
              obj.module
            )
          )
        )
      );
      setTeacherModules(uniqueModules);
      console.log(uniqueModules);
    });
  }, [selectedTeacher, direction]);

  useEffect(() => {
    let studyYearId = years.find((o) => o.study_year === currentYear.studyYear);
    if (chosenYear !== "" && chosenYear !== null) {
      studyYearId = years.find((o) => o.study_year === chosenYear);
    }
    if (
      updateState !== "" &&
      errors.module === "" &&
      errors.teacher === "" &&
      modulesByName.length !== 0
    ) {
      API.get(
        `survey/teacher/${searchedUser}/${studyYearId.id}/module/${modulesByName[0].id}/results`
      ).then((response) => {
        handleGetTeacherSurveyAnswers(response.data);
        if (response.data.data === null) {
          Object.assign(errors, {
            message: "Modulis neegzituoja arba mokytojas neturi šio modulio",
          });
        } else Object.assign(errors, { message: "" });
      });
    } else if (
      updateState !== "" &&
      errors.module === "" &&
      errors.teacher === "" &&
      modulesByName.length === 0
    ) {
      handleGetTeacherSurveyAnswers("");
      Object.assign(errors, {
        message: "Modulis neegzituoja arba mokytojas neturi šio modulio",
      });
    }
  }, [updateState, modulesByName, errors, searchedUser]);
  return (
    <div>
      <Navbar />
      <Account />
      <div className="container-fluid bg-gray-50 flex justify-center ml-80 w-3/4 min-h-screen">
        <h3 className="text-4xl font-bold teacher-form">
          Suminė apklausų ataskaita
        </h3>
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mudule-form"
        >
          <div className="createModule xl:w-96 mb-6">
            <button onClick={switchDirection} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-5'>Apkeisti paiešką</button>
            {direction ? (
              <>
                {/* Search by module */}
                <div>
                  <label
                    className="block text-gray-700 text-2xl font-bold my-3"
                    htmlFor="module_name"
                  >
                    Modulio pavadinimas
                  </label>
                  <select
                    name="module_name"
                    id="module_name"
                    className="form-select form-select-sm
                                              appearance-none
                                              block
                                              w-full
                                              px-2
                                              py-2
                                              text-sm
                                              font-normal
                                              text-gray-700
                                              bg-white bg-clip-padding bg-no-repeat
                                              border border-solid border-gray-300
                                              rounded
                                              transition
                                              ease-in-out
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    onChange={(e) => {
                      setSelectedModule(e.target.value);
                    }}
                    value={selectedModule ?? ""}
                  >
                    <option>Pasirinkite modulį</option>
                    {modules
                      ? modules.map((m) => (
                          <option key={nanoid()} value={m.module}>
                            {m.module}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                {errors.module ? (
                  <div
                    className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                    role="alert"
                  >
                    <span className="font-medium">{errors.module}</span>
                  </div>
                ) : null}

                <div>
                  <label
                    className="block text-gray-700 text-2xl font-bold mb-3 my-3"
                    htmlFor="user_id"
                  >
                    Mokytojo vardas
                  </label>

                  <select
                    name="user_id"
                    id="user_id"
                    className="form-select form-select-sm
                                              appearance-none
                                              block
                                              w-full
                                              px-2
                                              py-2
                                              text-sm
                                              font-normal
                                              text-gray-700
                                              bg-white bg-clip-padding bg-no-repeat
                                              border border-solid border-gray-300
                                              rounded
                                              transition
                                              ease-in-out
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    onChange={(e) => {
                      setSelectedTeacher(e.target.value);
                    }}
                    value={selectedTeacher ?? ""}
                  >
                    {moduleTeachers
                      ? moduleTeachers.map((t) => (
                          <option key={nanoid()} value={t}>
                            {t}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                {errors.teacher ? (
                  <div
                    className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                    role="alert"
                  >
                    <span className="font-medium">{errors.teacher}</span>
                  </div>
                ) : null}
              </>
            ) : (
              <>
              {/* Search by teacher */}

                <div>
                  <label
                    className="block text-gray-700 text-2xl font-bold mb-3 my-3"
                    htmlFor="user_id"
                  >
                    Mokytojo vardas
                  </label>

                  <select
                    name="user_id"
                    id="user_id"
                    className="form-select form-select-sm
                                              appearance-none
                                              block
                                              w-full
                                              px-2
                                              py-2
                                              text-sm
                                              font-normal
                                              text-gray-700
                                              bg-white bg-clip-padding bg-no-repeat
                                              border border-solid border-gray-300
                                              rounded
                                              transition
                                              ease-in-out
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    onChange={(e) => {
                      setSelectedTeacher(e.target.value);
                    }}
                    value={selectedTeacher ?? ""}
                  >
                  <option>Pasirinkite mokytoją</option>
                    {teachers
                      ? teachers.map((t) => (
                          <option key={nanoid()} value={t.fullName}>
                            {t.fullName}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                {errors.teacher ? (
                  <div
                    className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                    role="alert"
                  >
                    <span className="font-medium">{errors.teacher}</span>
                  </div>
                ) : null}
                {/* ------------------------------------ */}
                <div>
                  <label
                    className="block text-gray-700 text-2xl font-bold my-3"
                    htmlFor="module_name"
                  >
                    Modulio pavadinimas
                  </label>
                  <select
                    name="module_name"
                    id="module_name"
                    className="form-select form-select-sm
                                              appearance-none
                                              block
                                              w-full
                                              px-2
                                              py-2
                                              text-sm
                                              font-normal
                                              text-gray-700
                                              bg-white bg-clip-padding bg-no-repeat
                                              border border-solid border-gray-300
                                              rounded
                                              transition
                                              ease-in-out
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    onChange={(e) => {
                      setSelectedModule(e.target.value);
                    }}
                    value={selectedModule ?? ""}
                  >
                    {teacherModules
                      ? teacherModules.map((m) => (
                          <option key={nanoid()} value={m}>
                            {m}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                {errors.module ? (
                  <div
                    className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                    role="alert"
                  >
                    <span className="font-medium">{errors.module}</span>
                  </div>
                ) : null}
              </>
            )}

            <div className="">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white w-36 font-bold py-2 px-4 rounded mt-5"
              >
                Ieškoti
              </button>
            </div>
          </div>
        </form>
        {teacherSurveyAnswers && teacherSurveyAnswers.questions ? (
          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-1/2 m-3">
            <div className=" text-gray-700 text-2xl font-bold capitalize">
              Modulio &quot;{teacherSurveyAnswers.module}&quot; apklausa
            </div>
            <div className=" text-gray-700 text-2xl font-bold">
              Mokytojas: {teacherSurveyAnswers.teacher}
            </div>
            <div className=" text-gray-700 text-2xl font-bold">
              Apklausų vidurkis:
            </div>
            <div
              className=" text-gray-700 text-2xl font-bold"
              style={{ color: "red" }}
            >
              {Math.round(teacherSurveyAnswers.average * 100) / 100}
            </div>
            {teacherSurveyAnswers.questions.map((d) => (
              <RenderAnswers
                key={nanoid()}
                question={d.question}
                answers={d.answers}
                questionAverage={d.questionAverage}
                answer_options={d.answer_options}
              />
            ))}
          </div>
        ) : null}
        {errors.message ? (
          <div className="shadow-md px-8 pt-6 pb-8 mb-4 w-1/2 text-red-700 bg-red-100 rounded-lg">
            <h3 className="text-2xl font-bold flex justify-center">
              {errors.message}
            </h3>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default WorkerMenu;
