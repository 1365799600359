import React from 'react';
import API from '../../../API';

const RenderModules = (props) => {
  async function handleDeletion () {
    console.log('handleDeletion', props);

    try {
      await API.delete(`/module-subscriptions/${props.id}`)
        .then(response => {
          console.log(response);
        });
    } catch (err) {
      console.log(err);
    }
  }
  async function handleTeacherDeletion (index) {
    console.log('handleTeacherDeletion', props.teachers[index]);

    try {
      await API.delete(`/teacher-module-subscriptions/${props.id}/${props.teachers[index].id}`)
        .then(response => {
          console.log(response);
        });
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <tr className="bg-white border-b">
        <td className="px-6 py-4 font-medium text-gray-900">
          {props.module}
        </td>
        <td className="px-9 py-4">
          {props.studyYear}
        </td>
        <td className="px-9 py-4">
          {props.department}
        </td>
        <td className='px-9 py-4'>
          <button onClick={handleDeletion}>Šalinti prenumeratą</button>
        </td>
      </tr>
      {props.teachers.map((teacher, index) =>
        <tr key={index} className='bg-gray-800 text-white'>
          <td colSpan={3} className='px-9 py-2'>{teacher.full_name}</td>
          <td className='px-9 py-2'>
            <button onClick={() => handleTeacherDeletion(index)}>Šalinti mokytoją iš prenumeratos</button>
          </td>
        </tr>
      )}
    </>
  );
};
export default RenderModules;
