import { useModulesContext } from '../../context/ModulesContext';
import { useYearsContext } from '../../context/YearsContext';
import { Menu } from '@headlessui/react';
import React from 'react';

function classNames (...classes) {
  return classes.filter(Boolean).join(' ');
}

const RenderYear = (props) => {
  const { years, handleChosenYear } = useYearsContext();
  const AddYear = (data) => () => {
    handleChosenYear(data);
  };
  return (
    <>
      <Menu.Item>
        {({ active }) => (
          <a
            href="#"
            onClick={AddYear(props.study_year)}
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'block px-4 py-2 text-sm'
            )}
          >
            {props.study_year}
          </a>
        )}
      </Menu.Item>
    </>
  );
};
export default RenderYear;
