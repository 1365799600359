import {
  GET_DEPARTMENTS,
  GET_MODULES,
  GET_TEACHERS,
  GET_ALL_MODULES,
  GET_TEACHER_MODULES,
  GET_SURVEY,
  GET_TEACHER_SURVEY_ANSWERS,
  GET_TEACHER_SURVEY_ANSWERS_PAGINATE,
  SET_CHECKED, GET_MODULES_SUBSCRIPTION
} from './types';
export const getDepartments = (data) => {
  localStorage.setItem('departments', JSON.stringify(data));
  return {
    type: GET_DEPARTMENTS,
    payload: data
  };
};
export const getModules = (data) => {
  localStorage.setItem('modulesByName', JSON.stringify(data));
  return {
    type: GET_MODULES,
    payload: data
  };
};
export const getTeachers = (data) => {
  localStorage.setItem('teachers', JSON.stringify(data));
  return {
    type: GET_TEACHERS,
    payload: data
  };
};
export const getModulesSubscription = (data) => {
  localStorage.setItem('allModules', JSON.stringify(data));
  return {
    type: GET_MODULES_SUBSCRIPTION,
    payload: data
  };
};
export const getTeacherModules = (data) => {
  localStorage.setItem('teacherModules', JSON.stringify(data));
  return {
    type: GET_TEACHER_MODULES,
    payload: data
  };
};
export const getSurvey = (data) => {
  localStorage.setItem('survey', JSON.stringify(data));
  return {
    type: GET_SURVEY,
    payload: data
  };
};
export const getTeacherSurveyAnswers = (data) => {
  localStorage.setItem('getTeacherSurveyAnswers', JSON.stringify(data));
  return {
    type: GET_TEACHER_SURVEY_ANSWERS,
    payload: data
  };
};
export const getTeacherSurveyAnswersPaginate = (data) => {
  localStorage.setItem('getTeacherSurveyAnswersPaginate', JSON.stringify(data));
  return {
    type: GET_TEACHER_SURVEY_ANSWERS_PAGINATE,
    payload: data
  };
};
export const setChecked = (data) => {
  return {
    type: SET_CHECKED,
    payload: data
  };
};
export const getAllModules = (data) => {
  localStorage.setItem('allModules', JSON.stringify(data));
  return {
    type: GET_ALL_MODULES,
    payload: data
  };
};
