import API from '../../API';
const PostTeachers = (handleGetTeachers, search) => {
  return (
    API.post('/users/search/mokytojas', {
      name: search,
      limit: 100
    }).then(response => {
      handleGetTeachers(response.data);
    })
  );
};
export default PostTeachers;
