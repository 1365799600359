import { CHOSEN_YEAR, CURRENT_YEAR, GET_YEARS } from './types';

export const getAllYears = (data) => {
  localStorage.setItem('years', JSON.stringify(data));
  return {
    type: GET_YEARS,
    payload: data
  };
};
export const chosenYears = (data) => {
  localStorage.setItem('chosenYear', JSON.stringify(data));
  return {
    type: CHOSEN_YEAR,
    payload: data
  };
};
export const currentYear = (data) => {
  localStorage.setItem('currentYear', JSON.stringify(data));
  return {
    type: CURRENT_YEAR,
    payload: data
  };
};
