import {
  GET_DEPARTMENTS,
  GET_MODULES,
  GET_TEACHERS,
  GET_ALL_MODULES,
  GET_TEACHER_MODULES,
  SET_UPDATED,
  GET_SURVEY,
  GET_TEACHER_SURVEY_ANSWERS,
  GET_TEACHER_SURVEY_ANSWERS_PAGINATE,
  SET_CHECKED,
  GET_MODULES_SUBSCRIPTION
} from '../actions/types';
const ModulesReducer = (state, action) => {
  switch (action.type) {
  case GET_DEPARTMENTS:
    return {
      ...state,
      departments: action.payload
    };
  case GET_MODULES:
    return {
      ...state,
      modulesByName: action.payload
    };
  case GET_TEACHERS:
    return {
      ...state,
      teachers: action.payload
    };
  case GET_MODULES_SUBSCRIPTION:
    return {
      ...state,
      moduleSubscriptions: action.payload
    };
  case GET_TEACHER_MODULES:
    return {
      ...state,
      teacherModuleSubscriptions: action.payload
    };
  case SET_UPDATED:
    return {
      ...state,
      updateState: action.payload
    };
  case GET_SURVEY:
    return {
      ...state,
      survey: action.payload
    };
  case GET_TEACHER_SURVEY_ANSWERS:
    return {
      ...state,
      teacherSurveyAnswers: action.payload
    };
  case GET_TEACHER_SURVEY_ANSWERS_PAGINATE:
    return {
      ...state,
      teacherSurveyAnswersPaginate: action.payload
    };
  case SET_CHECKED:
    return {
      ...state,
      checked: action.payload
    };
  case GET_ALL_MODULES:
    return {
      ...state,
      allModules: action.payload
    };
  default:
    return state;
  }
};

export default ModulesReducer;
