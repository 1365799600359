import AuthCheck from '../../services/auth/AuthCheck';
import { useUpdateContext } from '../../context/UpdateContext';
import { useModulesContext } from '../../context/ModulesContext';
import { useYearsContext } from '../../context/YearsContext';
import React, { useEffect, useState } from 'react';
import API from '../../API';
import PostModules from '../../services/post/PostModules';
import Navbar from '../Navbar/Navbar';
import Account from '../Account/Account';
import RenderModules from './RenderModules';
import { nanoid } from 'nanoid';
import RenderTeachers from './RenderTeachers';
import RenderSurveyAnswersPaginate from './RenderSurveyAnswersPaginate';

const SurveyAnswerPages = () => {
  AuthCheck();
  const { updateState, handleUpdateState } = useUpdateContext();
  const { handleGetTeacherSurveyAnswersPaginate, teachers, handleGetModules, modulesByName, teacherSurveyAnswersPaginate } = useModulesContext();
  const { years, chosenYear, currentYear } = useYearsContext();
  const [modules, setModules] = useState([]);
  const [errors] = useState({});
  useEffect(() => {
    let chosenYearId = years.find(o => o.study_year === currentYear.studyYear);
    if (chosenYear !== '' && chosenYear !== null) {
      chosenYearId = years.find(o => o.study_year === chosenYear);
    }
    try {
      API.get('/users/module-subscriptions?page=1', {
        params: {
          study_year: chosenYearId.study_year,
          per_page: 999
        }

      })
        .then(response => {
          setModules(response.data.data);
        });
    } catch (err) {
      console.log(err);
    }
  }, [chosenYear]);
  const [searchedUser, setUser] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.target.user_id.value !== '') {
      setUser(teachers.find(t => t.fullName === event.target.user_id.value).id);
      Object.assign(errors, { teacher: '' });
    } else {
      Object.assign(errors, { teacher: 'Būtina pasirinkti mokytoją' });
    }
    if (event.target.module_name.value !== '') {
      Object.assign(errors, { module: '' });
    } else {
      Object.assign(errors, { module: 'Būtina įrašyti modulio pavadinimą' });
    }
    PostModules(handleGetModules, event.target.module_name.value);
    if (event.target.module_name.value !== '' && event.target.user_id.value !== '') {
      handleUpdateState(`search happened on: ${event.target.user_id.value} ${event.target.module_name.value}`);
    }
  };
  useEffect(() => {
    let studyYearId = years.find((o) => o.study_year === currentYear.studyYear);
    if (chosenYear !== '' && chosenYear !== null) {
      studyYearId = years.find((o) => o.study_year === chosenYear);
    }
    if (updateState !== '' && errors.module === '' && errors.teacher === '' && modulesByName.length !== 0) {
      API.get(
        `survey/teacher/${searchedUser}/${studyYearId.id}/module/${modulesByName[0].id}/preview`
      ).then((response) => {
        console.log(response.data);
        handleGetTeacherSurveyAnswersPaginate(response.data);
        if (response.data.data === null) {
          Object.assign(errors, { message: 'Modulis neegzituoja arba mokytojas neturi šio modulio' });
        } else Object.assign(errors, { message: '' });
      });
    } else if (updateState !== '' && errors.module === '' && errors.teacher === '') {
      Object.assign(errors, { message: 'Modulis neegzituoja arba mokytojas neturi šio modulio' });
    }
  }, [updateState, modulesByName, errors, searchedUser]);

  return (
    <div>
      <Navbar />
      <Account />
      <div className="container-fluid bg-gray-50 flex justify-center ml-80 w-3/4 min-h-screen">
        <h3 className="text-4xl font-bold teacher-form">Nesuminė apklausų ataskaita</h3>
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mudule-form"
        >
          <div className="createModule xl:w-96 mb-6">
            <div>
              <label
                className="block text-gray-700 text-2xl font-bold mb-3 my-3"
                htmlFor="module_name"
              >
                                Modulio pavadinimas
              </label>
              <input
                type="text"
                name="module_name"
                list="modules"
                className="form-select form-select-sm
                                            appearance-none
                                            block
                                            w-full
                                            px-2
                                            py-2
                                            text-sm
                                            font-normal
                                            text-gray-700
                                            bg-white bg-clip-padding bg-no-repeat
                                            border border-solid border-gray-300
                                            rounded
                                            transition
                                            ease-in-out
                                            m-0
                                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="Modulis"
              ></input>
            </div>
            {errors.module
              ? <div
                className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                role="alert"><span className="font-medium">{errors.module}</span>
              </div>
              : null}
            <datalist id="modules">
              {(modules)
                ? modules.map((d) => (
                  <RenderModules key={nanoid()} id={d.id} module={d.module} surveyActive={d.surveyActive} />))
                : null}
            </datalist>

            <div>
              <label
                className="block text-gray-700 text-2xl font-bold mb-3 my-3"
                htmlFor="module_name"
              >
                                Mokytojo vardas
              </label>
              <input
                type="text"
                name="user_id"
                list="user_id"
                className="form-select form-select-sm
                                            appearance-none
                                            block
                                            w-full
                                            px-2
                                            py-2
                                            text-sm
                                            font-normal
                                            text-gray-700
                                            bg-white bg-clip-padding bg-no-repeat
                                            border border-solid border-gray-300
                                            rounded
                                            transition
                                            ease-in-out
                                            m-0
                                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="Mokytojas"
              ></input>
              {errors.teacher
                ? <div
                  className="p-2.5 mb-3 text-sm text-red-700 bg-red-100 rounded-lg"
                  role="alert"><span className="font-medium">{errors.teacher}</span>
                </div>
                : null}

            </div>
            <datalist id="user_id">
              {(teachers)
                ? teachers.map((d) => (
                  <RenderTeachers key={nanoid()} id={d.id} fullName={d.fullName}/>))
                : null}
            </datalist>
            <div className="">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white w-36 font-bold py-2 px-4 rounded mt-5"
              >
                                Ieškoti
              </button>
            </div>
          </div>
        </form>
        {teacherSurveyAnswersPaginate.data
          ? <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-1/2">
            <h3 className="text-2xl font-bold capitalize">{teacherSurveyAnswersPaginate.data.name}</h3>
            {teacherSurveyAnswersPaginate.data
              ? teacherSurveyAnswersPaginate.data.questions.map(d =>
                <RenderSurveyAnswersPaginate key={nanoid()} id={d.id} question={d.question} answer={d.answer} answerOptions={d.answerOptions}/>)
              : null}

          </div>
          : null}
        {errors.message
          ? <div className="shadow-md px-8 pt-6 pb-8 mb-4 w-1/2 text-red-700 bg-red-100 rounded-lg">
            <h3 className="text-2xl font-bold flex justify-center">{errors.message}</h3>
          </div>
          : null}
      </div>
    </div>
  );
};
export default SurveyAnswerPages;
