import API from '../../API';
const PostGetUserByRole = (handleGetUserByRole, search) => {
  return API.post('/users/search', {
    name: search,
    per_page: 10
  }).then((response) => {
    handleGetUserByRole(response);
    console.log(response);
  });
};
export default PostGetUserByRole;
