import { useEffect } from 'react';
import API from '../../API';
import { useUserContext } from '../../context/UserContext';

const GetAllUsers = () => {
  const { handleGetUsers } = useUserContext();
  useEffect(() => {
    API.get('/users')
      .then(response => {
        handleGetUsers(response.data);
      });
  }, []);
};
export default GetAllUsers;
