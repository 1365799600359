let tokenStorage;
let userStorage;
let expireStorage;
let yearsStorage;
let chosenYearStorage;
let currentYearStorage;
let departmentsStorage;
let teachersStorage;
let allModulesStorageSubscription;
let getTeacherModulesStorage;
let getAllUsersStorage;
let getAllRoles;
let getUsersByRoleStorage;
let allModulesStorage;

try {
  tokenStorage = localStorage.getItem('token');
  userStorage = JSON.parse(localStorage.getItem('user'));
  expireStorage = localStorage.getItem('expire');
  yearsStorage = JSON.parse(localStorage.getItem('years'));
  chosenYearStorage = JSON.parse(localStorage.getItem('chosenYear'));
  currentYearStorage = JSON.parse(localStorage.getItem('currentYear'));
  departmentsStorage = JSON.parse(localStorage.getItem('departments'));
  teachersStorage = JSON.parse(localStorage.getItem('teachers'));
  allModulesStorageSubscription = JSON.parse(localStorage.getItem('allModules'));
  getTeacherModulesStorage = JSON.parse(localStorage.getItem('teacherModules'));
  getAllUsersStorage = JSON.parse(localStorage.getItem('allusers'));
  getAllRoles = JSON.parse(localStorage.getItem('allroles'));
  getUsersByRoleStorage = JSON.parse(localStorage.getItem('userByRole'));
  allModulesStorage = JSON.parse(localStorage.getItem('modules'));
} catch (error) {
  console.error('Duomenų apdorojimo klaida:', error);
  console.log('Vartotojas peradresuojamas į prisijungimo puslapį');
  localStorage.clear();
  window.location.pathname = '/';
}

export { tokenStorage };
export { userStorage };
export { expireStorage };
export { yearsStorage };
export { chosenYearStorage };
export { currentYearStorage };
export { departmentsStorage };
export { teachersStorage };
export { allModulesStorageSubscription };
export { getTeacherModulesStorage };
export { getAllUsersStorage };
export { getAllRoles };
export { getUsersByRoleStorage };
export { allModulesStorage };
