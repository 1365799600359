import kitm3 from '../kitm3.png';
import './navbar.css';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import React from 'react';

import 'react-toastify/dist/ReactToastify.css';
toast.configure();
const Navbar = () => {
  const { user } = useUserContext();
  const srcCode = 'martynas.kaselionis@kitm.lt';
  const notify = () => {
    toast.success(`Nukopijuota ${srcCode}`, { theme: 'colored' });
  };
  return (
    <>
      {user
        ? <nav>
          <aside className="w-2/6 fixed top-0 md:left-0 bottom-0 navbar-aside" aria-label="Sidebar">
            <Link to="/autentifikuota"><img src={kitm3} alt="Logo" className='manoimg'/></Link>
            <hr className="my-4"/>
            <div className="overflow-y-auto px-3 bg-white-50 rounded mx-8">
              <ul className="space-y-5">
                {user.roles.find(t => t.name === 'mokytojas')
                  ? <>
                    <div><h2 className="text-2xl font-bold">Modulių valdymas</h2></div>
                    {user.roles.find(t => t.name === 'mokytojas')
                      ? <li>
                        <Link to="/autentifikuota/mokytojo-moduliai"
                          className="app-link">
                          <svg className="w-6 h-6 text-blue-500 transition duration-75 group-hover:text-blue-900"
                            viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round">
                            <polygon points="12 2 2 7 12 12 22 7 12 2"/>
                            <polyline points="2 17 12 22 22 17"/>
                            <polyline points="2 12 12 17 22 12"/>
                          </svg>
                          <span className="ml-3">Mokytojo moduliai</span>
                        </Link>
                      </li>
                      : null}
                    <li>
                      <Link to="/autentifikuota/modulio-apklausa"
                        className="app-link">
                        <svg className="w-6 h-6 text-blue-500 transition duration-75 group-hover:text-blue-900"
                          strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                          strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z"/>
                          <path
                            d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18"/>
                          <line x1="13" y1="8" x2="15" y2="8"/>
                          <line x1="13" y1="12" x2="15" y2="12"/>
                        </svg>
                        <span className="flex-1 ml-3 whitespace-nowrap">Modulių apklausų aktyvavimas</span>
                      </Link>
                    </li>
                  </>
                  : null}
                {user.roles.find(t => t.name === 'admin') || user.roles.find(t => t.name === 'darbuotojas')
                  ? <>
                    <div><h2 className="text-2xl font-bold">Ataskaitos</h2></div>
                    <li>
                      <Link to="/autentifikuota/darbuotojo-menu"
                        className="app-link">
                        <svg className="w-6 h-6 text-blue-500 transition duration-75 group-hover:text-blue-900"
                          viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                          fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z"/>
                          <rect x="4" y="4" width="16" height="16" rx="2"/>
                          <line x1="4" y1="10" x2="20" y2="10"/>
                          <line x1="10" y1="4" x2="10" y2="20"/>
                        </svg>

                        <span className="flex-1 ml-3 whitespace-nowrap">Suminė apklausų ataskaita</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/autentifikuota/nesumine-apklausu-ataskaita"
                        className="app-link">
                        <svg className="w-6 h-6 text-blue-500 transition duration-75 group-hover:text-blue-900"
                          fill="none" viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                            d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"/>
                        </svg>
                        <span className="flex-1 ml-3 whitespace-nowrap">Nesuminė apklausų ataskaita</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/autentifikuota/apklausu-pildymas-2"
                        className="app-link">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-500 transition duration-75 group-hover:text-blue-900" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                        </svg>
                        <span className="flex-1 ml-3 whitespace-nowrap">Apklausų pildymas</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/autentifikuota/vizualine-duomenu-analize"
                        className="app-link">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-500 transition duration-75 group-hover:text-blue-900" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
                        </svg>
                        <span className="flex-1 ml-3 whitespace-nowrap">Vizualinė duomenų analizė</span>
                      </Link>
                    </li>
                  </>
                  : null}
                {user.roles.find(t => t.name === 'admin') || user.roles.find(t => t.name === 'darbuotojas')
                  ? <>
                    <div><h2 className="text-2xl font-bold">Administravimas</h2></div>
                    {user.roles.find(t => t.name === 'admin')
                      ? <>
                        <li>
                          <Link to="/autentifikuota/vartotoju-administravimas"
                            className="app-link">

                            <svg className="w-6 h-6 text-blue-500 transition duration-75 group-hover:text-blue-900"
                              fill="none" viewBox="0 0 24 24"
                              stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
                            </svg>
                            <span className="flex-1 ml-3 whitespace-nowrap">Vartotojų administravimas</span>
                          </Link>
                        </li>
                        {/* <li>
                                                    <Link to="/autentifikuota/departamentu-administravimas"
                                                          className="app-link">
                                                        <svg className="h-6 w-6 text-blue-500 transition duration-75 group-hover:text-blue-900" width="24" height="24"
                                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z"/>
                                                            <circle cx="9" cy="7" r="4"/>
                                                            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"/>
                                                            <path d="M16 11l2 2l4 -4"/>
                                                        </svg>
                                                        <span className="flex-1 ml-3 whitespace-nowrap">Grupių administravimas</span>
                                                    </Link>
                                                </li> */}
                        <li>
                          <Link to="/autentifikuota/apklausu-importavimas"
                            className="app-link">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-500 transition duration-75 group-hover:text-blue-900"
                              fill="none" viewBox="0 0 24 24"
                              stroke="currentColor" >
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                            </svg>
                            <span className="flex-1 ml-3 whitespace-nowrap">Apklausų importavimas</span>
                          </Link>
                        </li>
                      </>
                      : null}
                    <li>
                      <Link
                        to="/autentifikuota/sukurti-modulio-prenumerata"
                        className="app-link"
                      >
                        <svg className="w-6 h-6 text-blue-500 transition duration-75 group-hover:text-blue-900"
                          viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                          fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z"/>
                          <path d="M14 3v4a1 1 0 0 0 1 1h4"/>
                          <path
                            d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"/>
                          <line x1="12" y1="11" x2="12" y2="17"/>
                          <line x1="9" y1="14" x2="15" y2="14"/>
                        </svg>
                        <span className="flex-1 ml-3 whitespace-nowrap">
                                                    Modulių prenumeratos kūrimas
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/autentifikuota/sukurti-moduli"
                        className="app-link"
                      >
                        <svg className="h-6 w-6 text-blue-500 transition duration-75 group-hover:text-blue-900"
                          viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                          fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z"/>
                          <rect x="4" y="4" width="6" height="6" rx="1"/>
                          <rect x="4" y="14" width="6" height="6" rx="1"/>
                          <rect x="14" y="14" width="6" height="6" rx="1"/>
                          <line x1="14" y1="7" x2="20" y2="7"/>
                          <line x1="17" y1="4" x2="17" y2="10"/>
                        </svg>
                        <span className="flex-1 ml-3 whitespace-nowrap">
                                                    Modulių kūrimas
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/autentifikuota/mokslo-metai"
                        className="app-link">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-500 transition duration-75 group-hover:text-blue-900" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <span className="flex-1 ml-3 whitespace-nowrap">Mokslo metų sukūrimas</span>
                      </Link>
                    </li>
                  </>
                  : null}
                {user.roles.find(t => t.name === 'moksleivis')
                  ? <>
                    <div><h2 className="text-2xl font-bold">Mokinio meniu</h2></div>
                    <li>
                      <Link to="/autentifikuota/modulio-pasirinkimas"
                        className="app-link">
                        <svg className="w-6 h-6 text-blue-500 transition duration-75 group-hover:text-blue-900"
                          width="24" height="24" viewBox="0 0 24 24"
                          strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                          strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z"/>
                          <rect x="3" y="4" width="18" height="16" rx="3"/>
                          <circle cx="9" cy="10" r="2"/>
                          <line x1="15" y1="8" x2="17" y2="8"/>
                          <line x1="15" y1="12" x2="17" y2="12"/>
                          <line x1="7" y1="16" x2="17" y2="16"/>
                        </svg>
                        <span className="flex-1 ml-3 whitespace-nowrap">Modulių apklausos</span>
                      </Link>
                    </li>
                  </>
                  : null}
                <li>
                  <div className="email-position">
                    <div className="">Visais klausimais dėl sistemos naudojimo galima kreiptis tik el.paštu</div>
                    <div className="flex flex-direction-row gap-8">
                      <div className="font-bold">{srcCode}</div>
                      <CopyToClipboard text={srcCode}>
                        <button onClick={notify} aria-label="Kopijuoti el.paštą">
                          <svg className="h-6 w-6 text-blue-500" width="24" height="24"
                            viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                            fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z"/>
                            <path
                              d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"/>
                            <circle cx="12" cy="14" r="2"/>
                            <polyline points="14 4 14 8 8 8 8 4"/>
                          </svg>
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </aside>

        </nav>
        : null}
    </>
  );
};
export default Navbar;
