import GetYears from '../../services/get/GetYears';
import GetAllModules from '../../services/get/GetAllModules';
import React, { useEffect } from 'react';
import PostTeachers from '../../services/post/PostTeachers';
import { useModulesContext } from '../../context/ModulesContext';
import './main.css';
import GetAllUsers from '../../services/get/GetAllUsers';
import GetRoles from '../../services/get/GetRoles';
import Navbar from '../Navbar/Navbar';
import Account from '../Account/Account';
import AuthCheck from '../../services/auth/AuthCheck';
import { useUserContext } from '../../context/UserContext';
import API from '../../API';

const Main = () => {
  const { handleGetTeachers, handleAllTeacherModules } = useModulesContext();
  const { user } = useUserContext();
  AuthCheck();
  useEffect(() => {
    if (user) {
      if (user.roles.find(t => t.name === 'admin') || user.roles.find(t => t.name === 'darbuotojas')) {
        PostTeachers(handleGetTeachers, '');
      }
    }

    API.get('/student/module-subscriptions')
      .then(resp => {
        if (resp.status !== 200) {
          console.error('Studentų modulių prenumeratos klaida', resp);
        }
        handleAllTeacherModules(resp);
      });
  }, []);
  if (user) {
    if (user.roles.find(t => t.name === 'admin') || user.roles.find(t => t.name === 'darbuotojas')) {
      GetYears();
      GetAllModules();
      GetAllUsers();
      GetRoles();
    }
  }
  if (user) {
    if (user.roles.find(t => t.name === 'mokytojas')) {
      GetAllModules();
      GetYears();
    }
  }
  return (
    <>
      <Navbar/>
      <Account/>
      <div className="container-fluid bg-gray-50 ml-80 w-3/4 min-h-screen">
        <h3 className="text-3xl font-bold capitalize mt-60 w-3/6 flex text-center">Kauno informacinių technologijų mokyklos kokybės užtikrinimo sistema</h3>
      </div>
    </>
  );
};
export default Main;
