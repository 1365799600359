import React from 'react';

const RenderModulesByName = (props) => {
  return (
    <a href="src/components/Modules/Renders/RenderModulesByName#"
      className="block p-6 max-w-sm w-64 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100">
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{props.name}</h5>
    </a>
  );
};
export default RenderModulesByName;
