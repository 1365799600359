import { useYearsContext } from '../../context/YearsContext';
import { Menu, Transition } from '@headlessui/react';
import './years.css';
import React, { Fragment } from 'react';
import RenderYear from './RenderYear';
import { nanoid } from 'nanoid';
const Years = () => {
  const { years, chosenYear, currentYear } = useYearsContext();
  return (
    <Menu as="div" className="relatives">
      <Menu.Button className="grid justify-items-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
        <div>
          {(currentYear.studyYear !== null && chosenYear === '') ||
                    chosenYear === null
            ? currentYear.studyYear
            : chosenYear}
        </div>
        <div className="">
          <svg
            className="h-5 w-8 text-black"
            width="12"
            height="12"
            viewBox="0 0 20 20"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <polyline points="3 8 7 4 11 8" />
            <line x1="7" y1="4" x2="7" y2="13" />
            <polyline points="13 16 17 20 21 16" />
            <line x1="17" y1="10" x2="17" y2="20" />
          </svg>
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="grid justify-items-center w-full rounded-md border border-gray-300 shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          <div className="py-1">
            {years
              ? years.map((d) => (
                <RenderYear key={nanoid()} id={d.id} study_year={d.study_year} active={d.active}/>))
              : null}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Years;
