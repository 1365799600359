import {
  CHOSEN_YEAR,
  CURRENT_YEAR,
  GET_YEARS
} from '../actions/types';
const YearsReducer = (state, action) => {
  switch (action.type) {
  case GET_YEARS:
    return {
      ...state,
      years: action.payload
    };
  case CHOSEN_YEAR:
    return {
      ...state,
      chosenYear: action.payload
    };
  case CURRENT_YEAR:
    return {
      ...state,
      currentYear: action.payload
    };
  default:
    return state;
  }
};

export default YearsReducer;
