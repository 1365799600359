import React from 'react';
import '../index.css';
import Navbar from '../../Navbar/Navbar';
import Account from '../../Account/Account';
import API from '../../../API';
import AuthCheck from '../../../services/auth/AuthCheck';
import GetModules from '../../../services/get/GetModules';
import { useModulesContext } from '../../../context/ModulesContext';
import RenderAllModulesForUpdate from '../Renders/RenderAllModulesForUpdate';
import { nanoid } from 'nanoid';
import { useUpdateContext } from '../../../context/UpdateContext';
const ModuleCreate = () => {
  AuthCheck();
  const { allModules } = useModulesContext();
  const { updateState } = useUpdateContext();
  GetModules(updateState);
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(event.target.module_name.value);
    try {
      API.post('/modules', {
        module_name: event.target.module_name.value
      })
      .then(response => {
        console.log('Modulių sukurimas', response);
      });
    } catch (err) {
      console.error('Modulių sukūrimo klaida', err);
    }
  };
  return (
    <>
      <Navbar />
      <Account />

      <div className="container-fluid bg-gray-50 ml-80 w-3/4 min-h-screen">
        <h3 className="text-4xl font-bold mudule-form">Modulių kūrimas</h3>
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mudule-form">
          <div className="createModule xl:w-96 mb-6">
            <div>
              <label className="block text-gray-700 text-2xl font-bold mb-3 my-3" htmlFor="module_name">
                                Modulio pavadinimas
              </label>
              <input type="text"
                multiple
                name="module_name"
                className="form-select form-select-sm
                                        appearance-none
                                        block
                                        w-full
                                        px-2
                                        py-2
                                        text-sm
                                        font-normal
                                        text-gray-700
                                        bg-white bg-clip-padding bg-no-repeat
                                        border border-solid border-gray-300
                                        rounded
                                        transition
                                        ease-in-out
                                        m-0
                                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="Modulio pavadinimas"
              >
              </input>
            </div>
            <div className="">
              <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white w-36 font-bold py-2 px-4 rounded mt-5">
                                Sukurti
              </button>
            </div>
          </div>
        </form>
        <div className='w-2/3 mt-5 overflow-x-hidden shadow-md sm:rounded-lg'>
          <table className='w-full text-sm text-left text-gray-500 mr-7'>
            <thead className='text-xs uppercase bg-blue-500 text-white'>
              <tr>
                <td className='px-3 py-3'>Modulis</td>
                <td className='px-3 py-3'>Veiksmai</td>
              </tr>
            </thead>
            <tbody>
              {allModules ? allModules.map(i => <RenderAllModulesForUpdate key={nanoid()} id={i.id} name={i.name}/>) : null}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default ModuleCreate;
