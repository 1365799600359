import React, { useEffect, useState } from "react";
import "../index.css";
import { MultiSelect } from "react-multi-select-component";
import { useModulesContext } from '../../../context/ModulesContext';
import API from '../../../API';
const SearchModulesByName = (props) => {
  const { teachers, allModules } = useModulesContext();
  const { setSearchQuery } = props;
  const [departments, setDepartments] = useState(null);

  useEffect(() => {
    API.post('/departments/search', {
      department: ''
    }).then(response => {
      setDepartments(response.data);
    })
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    let searchQ = [];

    if (event.target.module.value !== "") {
      searchQ.push("&module=" + event.target.module.value);
    }
    if (event.target.department.value !== "") {
      searchQ.push("&department=" + event.target.department.value);
    }
    if (event.target.teacher.value !== "") {
      searchQ.push("&teacher=" + event.target.teacher.value);
    }

    if (searchQ.length !== 0) {
      setSearchQuery(searchQ.join(""));
    } else {
      setSearchQuery("");
    }
    console.log("Searching for: ", event.target.module.value);
  };
  
  return (
    <>
      <p className="text-center text-2xl">Paieška</p>
      <form onSubmit={handleSubmit} className="modules">
        {allModules?.length > 0 ? <>
          <select name="module" id="module" className="mx-2 form-select form-select-sm appearance-none block w-96 px-2 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
            <option value="">Pasirinkite modulį</option>
            {allModules.map(module =>
              <option value={module.name} key={module.name}>{module.name}</option>
            )}
          </select>
        </> : null}

        {departments?.length > 0 ? <>
          <select name="department" id="department" className="mx-2 form-select form-select-sm appearance-none block w-96 px-2 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
            <option value="">Pasirinkite departamentą</option>
            {departments.map(department =>
              <option value={department.department} key={department.department}>{department.department}</option>
            )}
          </select>
        </> : null}
        
        {teachers?.length > 0 ? <>
          <select name="teacher" id="teacher" className="mx-2 form-select form-select-sm appearance-none block w-96 px-2 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
            <option value="">Pasirinkite mokytoją</option>
            {teachers.map(teacher =>
              <option value={teacher.fullName} key={teacher.fullName}>{teacher.fullName}</option>
            )}
          </select>
        </> : null}
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-2 rounded"
        >
          Ieškoti
        </button>
      </form>
    </>
  );
};
export default SearchModulesByName;
